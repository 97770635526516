import React, { Suspense } from "react";
import { Router, Switch } from "react-router-dom";
import history from "./History";
import * as LazyComponent from "../utils/LazyLoaded";
import { PrivateRoute } from "../components/PrivateRoute/PrivateRoute";
import { Loader } from "components/Loader/Loader";

const Routes = (
  <Suspense fallback={<Loader />}>
    <Router history={history}>
      <Switch>
        <LazyComponent.Login path="/login" exact permission={true} />
        <LazyComponent.RedirectLogin
          path="/redirect-login"
          exact
          permission={true}
        />
        <PrivateRoute
          path="/forgetPassword"
          exact
          component={LazyComponent.ForgetPassword}
          permission={true}
        />
        <PrivateRoute
          path="/resetPassword"
          exact
          component={LazyComponent.ResetPassword}
          permission={true}
        />
        <PrivateRoute
          path="/"
          exact
          component={LazyComponent.DashboardSetup}
          permission={true}
        />
        <PrivateRoute
          path="/setup"
          component={LazyComponent.Setup}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/recruitmentPlan"
          component={LazyComponent.RecruitmentPlan}
          permission={["SETUP"]}
        />

        <PrivateRoute
          path="/companySetup"
          exact
          component={LazyComponent.CompanySetup}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/companyEdit"
          exact
          component={LazyComponent.EditCompanyDetails}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/branch/:id?"
          exact
          component={LazyComponent.AddEditBranches}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/jobRoles"
          exact
          component={LazyComponent.JobRole}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/integrations"
          exact
          component={LazyComponent.Integration}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/AddEditUnifonic"
          exact
          component={LazyComponent.AddEditUnifonicIntegration}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/AddEditOffice365"
          exact
          component={LazyComponent.AddEditOffice365Integration}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/jobRole/:id?"
          exact
          component={LazyComponent.AddEditJobRole}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/digitalDocument/:id?"
          exact
          component={LazyComponent.AddEditDigitalDocuments}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/agent/:id?"
          exact
          component={LazyComponent.AddEditAgents}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/agents"
          exact
          component={LazyComponent.ViewAgents}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/salaryGrades"
          exact
          component={LazyComponent.ViewSalaryGrades}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/salaryGrade/:id?"
          exact
          component={LazyComponent.AddEditSalaryGrades}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/jobRequisitionSetup"
          exact
          component={LazyComponent.JobRequisitionSetup}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/jobTitles"
          exact
          component={LazyComponent.JobTitlesSetup}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/emailTemplates/:id?"
          exact
          component={LazyComponent.EmailTemplates}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/rejectionReasons/:id?"
          exact
          component={LazyComponent.RejectionReasons}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/vacationBalances/:id?"
          exact
          component={LazyComponent.VacationBalances}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/contractTemplate"
          exact
          component={LazyComponent.ContractTemplate}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/addRejectionReason"
          exact
          component={LazyComponent.AddEditRejectionReason}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/addVacationBalances"
          exact
          component={LazyComponent.AddEditVacationBalances}
          permission={["SETUP"]}
        />

        <PrivateRoute
          path="/addTicket"
          exact
          component={LazyComponent.AddTicket}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/tickets"
          exact
          component={LazyComponent.TicketsList}
          permission={["SETUP"]}
        />

        <PrivateRoute
          path="/editVacationBalances/:id"
          exact
          component={LazyComponent.AddEditVacationBalances}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/editRejectionReason/:id"
          exact
          component={LazyComponent.AddEditRejectionReason}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/editEmailTemplate/:id"
          exact
          component={LazyComponent.CreateEmailTemplate}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/createEmailTemplate"
          exact
          component={LazyComponent.CreateEmailTemplate}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/jobTitle/:id?"
          exact
          component={LazyComponent.AddEditJobTitle}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/invoices"
          exact
          component={LazyComponent.Invoices}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/manpowerRequests/:id?"
          exact
          component={LazyComponent.ManpowerRequests}
          permission={true}
        />
        <PrivateRoute
          path="/viewAllRequests"
          exact
          component={LazyComponent.ViewAllRequests}
          permission={[
            "VIEW_PENDING_JOB_REQUESTS",
            "LIST_JOB_REQUESTS",
            "VIEW_CLOSED_JOB_REQUESTS",
          ]}
        />
        <PrivateRoute
          path="/advertisementSetup"
          exact
          component={LazyComponent.AdvertisementSetup}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/profile"
          exact
          component={LazyComponent.AgentProfile}
          permission={true}
        />
        <PrivateRoute
          path="/changePassword"
          exact
          component={LazyComponent.AgentChangePassword}
          permission={true}
        />
        <PrivateRoute
          path="/advertisementPreview"
          exact
          component={LazyComponent.AdvertisementPreview}
          permission={["JOB_REQUISITION"]}
        />
        <PrivateRoute
          path="/viewJobSummary/:id?"
          exact
          component={LazyComponent.viewJobSummary}
          permission={["VIEW_PUBLISHED_JOB_REQUESTS"]}
        />
        <PrivateRoute
          path="/manageCandidates/:id"
          exact
          component={LazyComponent.manageCandidates}
          permission={true}
        />
        <PrivateRoute
          path="/jobOpenings"
          exact
          component={LazyComponent.JobOpenings}
          permission={["LIST_ALL_JOB_OPENINGS"]}
        />
        <PrivateRoute
          path="/interviewEvaluationForm"
          exact
          component={LazyComponent.InterviewEvaluationForm}
          permission={["VIEW_INTERVIEW_EVALUATION_FORM"]}
        />
        <PrivateRoute
          path="/competences"
          exact
          component={LazyComponent.Competences}
          permission={["COMPETENCES_SETTING"]}
        />
        <PrivateRoute
          path="/digitalDocuments"
          exact
          component={LazyComponent.DigitalDocuments}
          // permission={["COMPETENCES_SETTING"]}
        />
        <PrivateRoute
          path="/jobRole/:id?"
          exact
          component={LazyComponent.AddEditJobRole}
          permission={["SETUP"]}
        />
        <PrivateRoute
          path="/Archive"
          exact
          component={LazyComponent.JobOpeningsArchive}
          permission={["LIST_ALL_JOB_OPENINGS"]}
        />
        <PrivateRoute
          path="/myInterviews"
          exact
          component={LazyComponent.MyInterviews}
          permission={["MY_INTERVIEW_GRID"]}
        />
        <PrivateRoute
          path="/interview/:id"
          exact
          component={LazyComponent.VideoInterview}
          permission={true}
        />
        <PrivateRoute
          path="/CandidatePortalSetup"
          exact
          component={LazyComponent.CandidatePortalSetup}
          permission={true}
        />
        <PrivateRoute
          path="/Requests"
          exact
          component={LazyComponent.Requests}
          permission={["APPROVAL"]}
        />
        <PrivateRoute
          path="/RequestDetails/:id"
          exact
          component={LazyComponent.RequestDetails}
          permission={true}
        />
        <PrivateRoute
          path="/applicantInterviews"
          exact
          component={LazyComponent.ApplicantInterviews}
          permission={true}
        />
        <PrivateRoute
          path="/applicantScreening"
          exact
          component={LazyComponent.ApplicantScreening}
          permission={true}
        />
        <PrivateRoute
          path="/applicantProfile"
          exact
          component={LazyComponent.ApplicantProfile}
          permission={true}
        />
        <PrivateRoute
          path="/addPipelineFlow/:id?"
          exact
          component={LazyComponent.PipelineSetup}
          permission={true}
        />
        <PrivateRoute
          path="/pipelineFlows"
          exact
          component={LazyComponent.PipelineFlowsGrid}
          permission={true}
        />
        <PrivateRoute
          path="/reminders"
          exact
          component={LazyComponent.Reminders}
          permission={true}
        />
        <PrivateRoute
          path="/preOfferDocs"
          exact
          component={LazyComponent.PreOfferDocsList}
          permission={["VIEW_DOCUMENTS"]}
        />
        <PrivateRoute
          path="/preofferDoc/:id?"
          exact
          component={LazyComponent.AddEditPreOfferDocs}
          permission={["CREATE_DOCUMENTS", "EDIT_DOCUMENTS"]}
        />
        <PrivateRoute
          path="/cardsSetup"
          exact
          component={LazyComponent.CardsSetup}
          permission={true}
        />
        <PrivateRoute
          path="/checkoutStatus"
          exact
          component={LazyComponent.CheckoutStatus}
          permission={true}
        />
        {/* Hide Subscription adaa  */}
        {/* <PrivateRoute
          path="/subscriptionPlans"
          exact
          component={LazyComponent.SubscriptionPlans}
          permission={true}
        /> */}
        <PrivateRoute
          path="/offerTemplate"
          exact
          component={LazyComponent.OfferTemplateSettings}
          permission={true}
        />
        <PrivateRoute
          path="/candidateApprovals"
          exact
          component={LazyComponent.CandidateApprovals}
          permission={true}
        />
        <PrivateRoute
          path="/TalentPool"
          exact
          component={LazyComponent.CandidateTalentPool}
          permission={true}
        />
        <PrivateRoute
          path="/OrganizationalStructure"
          exact
          component={LazyComponent.OrganizationalStructure}
          permission={true}
        />
        <PrivateRoute
          path="/positionChart"
          exact
          component={LazyComponent.PositionChart}
          permission={true}
        />
        <PrivateRoute
          path="/report"
          exact
          component={LazyComponent.Report}
          permission={["VIEW_REPORTS"]}
        />
        <PrivateRoute
          path="/notificationList"
          exact
          component={LazyComponent.NotificationList}
          permission={true}
        />
        <PrivateRoute
          path="/approvalSetup"
          exact
          component={LazyComponent.ApprovalSetup}
          permission={true}
        />
        <PrivateRoute
          path="/ManageCandidateProfile"
          exact
          component={LazyComponent.ManageCandidateProfile}
          permission={true}
        />
        <PrivateRoute
          path="/ManageJobDetails"
          exact
          component={LazyComponent.ManageJobDetails}
          permission={true}
        />
        <PrivateRoute
          path="/CandidateProfileSetup"
          exact
          component={LazyComponent.CandidateProfileSetup}
          permission={true}
        />
        <LazyComponent.addAgentPassword path="/addPassword" exact />
        <LazyComponent.NotFound
          path="**"
          title="This page doesn't exist..."
          exact
        />
      </Switch>
    </Router>
  </Suspense>
);

export default Routes;
