import * as types from "../types/dashboard";
import { manipulateData } from "containers/Report/utils";

export default (
  state = {
    manipulateData: {},
    report: {},
    agentRequestedConfirmEmail: false,
    opened_job_posts: 0,
    closed_job_posts: 0,
    joiners: {},
    monthlyHiring: {},
    agentJobs: [],
    jobStages: [],
    cvs: null,
    excel: null,
    jobReport: null,
  },
  action
) => {
  switch (action.type) {
    case types.DASHBOARD_CARDS_DATA_RECEIVE:
      return { ...state, ...action.payload };
    case types.REPORT_DATA_RECIEVE:
      return {
        ...state,
        report: action.payload,
        manipulateData: manipulateData(action.payload),
      };
    case types.VERIFY_EMAIL_RECIEVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.JOINERS_REPORT_RECIEVE:
      return {
        ...state,
        joiners: action.payload,
      };
    case types.JOB_STAGES_REPORTS_RECIEVE:
      return {
        ...state,
        jobStages: [...action.payload],
      };
    case types.AGENT_JOB_REPORT_RECIEVE:
      return {
        ...state,
        agentJobs: action.payload,
      };

    case types.MONTHLY_HIRING_REPORT_RECIEVE:
      return {
        ...state,
        monthlyHiring: action.payload,
      };
    case types.EXPORT_CVS_RECIEVE:
      return {
        ...state,
        cvs: action.payload,
      };
    case types.EXPORT_EXCEL_RECIEVE:
      return {
        ...state,
        excel: action.payload,
      };
    case types.JOB_EXPORT_REPORT_RECIEVE:
      return {
        ...state,
        jobReport: action.payload,
      };
    default:
      return state;
  }
};
