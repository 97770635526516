import * as types from "../types/tickets";

const INITIAL_STATE = {
  isTicketDeleted: false,
  paging: {},
  data: [],
  currentTicket: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_TICKETS_RECIEVE:
      return { ...state, agent: action.payload };
    case types.GET_ALL_TICKETS_RECIEVE:
      return {
        ...state,
        data: action.payload?.data,
        paging: action.payload?.paging,
      };
    case types.GET_SINGLE_TICKET_RECIEVE:
      return { ...state, currentTicket: action.payload };
    case types.DELETE_TICKETS_RECIEVE:
      return { ...state, isTicketDeleted: action.payload };

    default:
      return state;
  }
};
