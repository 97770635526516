import * as types from "../types/viewAllRequests";

//INPROGRESS
export const requestRequestsInProgress = (payload) => {
  return {
      type: types.REQUEST_REQUESTS_IN_PROGRESS,
      payload,
  };
};
export const receiveRequestsInProgress = (payload) => ({
  type: types.RECEIVE_REQUESTS_IN_PROGRESS,
  payload,
});
export const deleteRequestInprogress = (payload) => ({
  type: types.DELETE_REQUEST_IN_PROGRESS,
  payload,
});

//PUBLISHED
export const publishedJobsRequset = (data) => ({
  type: types.PUBLISHED_JOBS_REQUEST,
  payload: data,
});

export const publishedJobsRecieve = (data) => ({
  type: types.PUBLISHED_JOBS_RECIEVE,
  payload: data,
});

//CLOSED
export const requestRequestsClosed = (payload) => ({
  type: types.REQUESTS_CLOSED_REQUEST,
  payload,
});
export const receiveRequestsClosed = (payload) => ({
  type: types.REQUESTS_CLOSED_RECEIVE,
  payload,
});

export const requestCheckJobsLimit = () => ({
  type: types.CHECK_JOB_REQUESTS_PLAN_LIMIT_REQUEST,
});
export const receiveCheckJobsLimit = (payload) => ({
  type: types.CHECK_JOB_REQUESTS_PLAN_LIMIT_RECEIVE,
  payload,
});

// ALL JOB OPENINGS
export const requestAllJobOpenings = (payload) => ({
  type: types.REQUEST_ALL_JOB_OPENINGS,
  payload,
});
export const receiveAllJobOpenings = (payload) => ({
  type: types.RECEIVE_ALL_JOB_OPENINGS,
  payload,
});

// DELAY CLOSING DATE
export const delayClosingDateRequest = (payload) => ({
  type: types.DELAY_CLOSING_DATE_REQUSET,
  payload,
});
export const delayClosingDateRecieve = (payload) => ({
  type: types.DELAY_CLOSING_DATE_RECIEVE,
  payload,
});

export const requestApprovalStatusFLow = (payload) => ({
  type: types.APPROVAL_SETUP_STATUS_FLOW_REQUEST,
  payload,
});
export const receiveApprovalStatusFLow = (payload) => ({
  type: types.APPROVAL_SETUP_STATUS_FLOW_RECEIVE,
  payload,
});

export const requestCompanyInterviewMinutes = () => ({
  type: types.COMPANY_INTERVIEW_MINUTES_REQUEST,
});
export const receiveCompanyInterviewMinutes = (payload) => ({
  type: types.COMPANY_INTERVIEW_MINUTES_RECEIVE,
  payload,
});
export const closeJobOpening = (payload) => ({
  type: types.CLOSE_JOB_OPENING_REQUEST,
  payload,
});

export const holdPublishedJobsRequest = (payload) => ({
  type: types.HOLD_PUBLISHED_JOBS_REQUEST,
  payload,
});

export const reOpenHoldedJobsRequest = (payload) => ({
  type: types.REOPEN_HOLDED_JOBS_REQUEST,
  payload,
});

export const getHoldedJobsRequest = (payload) => ({
  type: types.GET_HOLDED_JOBS_REQUEST,
  payload,
});

export const getHoldedJobsReceive = (payload) => ({
  type: types.GET_HOLDED_JOBS_RECEIVE,
  payload,
});

export const duplicateJobPostRequest = (payload) => ({
  type: types.DUPLICATE_JOB_POST_REQUEST,
  payload,
});

export const duplicateJobPostReceive = (payload) => ({
  type: types.DUPLICATE_JOB_POST_RECEIVE,
  payload,
});
export const firstViewALLRequestJobOpeningRequest = (payload) => ({
  type: types.FIRST_VIEW_ALL_REQUEST_AND_JOB_OPENING_REQUEST,
  payload,
});
export const firstViewALLRequestJobOpeningRecieve = (payload) => ({
  type: types.FIRST_VIEW_ALL_REQUEST_AND_JOB_OPENING_RECIEVE,
  payload,
});

// toggle archive

export const toggleArchiveRequest = (payload) => ({
  type: types.TOGGLE_ARCHIVE_REQUSET,
  payload,
});
export const toggleArchiveRecieve = (payload) => ({
  type: types.TOGGLE_ARCHIVE_RECIEVE,
  payload,
});
