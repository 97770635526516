import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types/tickets";
import API from "../../network/apis/tickets";
import { dispatchError, showSnackbar, getURLParams } from "utils/shared";
import * as ACTION from "../actions/ticket";

export function* getAllTicketsRequest({ payload }) {
  try {
    const response = yield call(API.getAllTicketsRequest, payload);
    yield put(ACTION.getAllTicketsRecieve(response.data));
  } catch (err) {
    console.log(err);
    dispatchError(err.response?.data);
  }
}

export function* addTicketsRequest({ payload }) {
  try {
    yield call(API.addTicketsRequest, payload);
    showSnackbar("savedSuccessfuly");
    setTimeout(() => (window.location.href = "/Tickets?page=1"), 100);
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* editTicketsRequest({ payload }) {
  try {
    const response = yield call(API.editTicketsRequest, payload);
    showSnackbar("savedSuccessfully");
    setTimeout(
      () =>
        (window.location.href = `/Tickets?page=${
          payload.page ? payload.page : 1
        }`),
      100
    );
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* getSingleTicketRequest({ payload }) {
  try {
    const response = yield call(API.getSingleTicketRequest, payload);
    yield put(ACTION.getSingleTicketRecieve(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* deleteTicketsRequest({ payload }) {
  try {
    yield call(API.deleteTicketsRequest, payload);
    yield put(ACTION.deleteTicketsRecieve(true));
    showSnackbar("done");
    // call get list rejection reasons "sagas"
    yield call(getAllTicketsRequest, {
      payload: { pageNumber: getURLParams("page"), page_size: 10 },
    });
  } catch (err) {
    dispatchError(err?.response?.data);
  }
}

export function* TicketsSagasWatch() {
  yield takeEvery(types.ADD_TICKETS_REQUEST, addTicketsRequest);
  yield takeEvery(types.DELETE_TICKETS_REQUEST, deleteTicketsRequest);
  yield takeEvery(types.EDIT_TICKET_REQUEST, editTicketsRequest);
  yield takeEvery(types.GET_ALL_TICKETS_REQUEST, getAllTicketsRequest);
  yield takeEvery(types.GET_SINGLE_TICKET_REQUEST, getSingleTicketRequest);
}
