import { call, put } from "redux-saga/effects";
import API from "../../network/apis/manageCandidates";
import * as ACTION from "../actions/manageCandidates";
import store from "../../store";
import History from "routes/History";
import {
  dispatchError,
  showSnackbar,
  getURLParams,
  setURLParams,
} from "utils/shared";
import * as type from "../types/manageCandidates";
import { takeLatest } from "redux-saga/effects";
import ReactGA from "react-ga";
import {
  requestApplicantMainData,
  requestApplicantProfile,
} from "store/actions/applicantProfile";
import { candidateTalentPoolRequest } from "store/actions/candidateTalentPool";
import {
  interviewDetailsRequest,
  myInterviewsRequest,
  myPrevInterviewsRequest,
} from "store/actions/MyInterviews";
import { getTemplateEmailRequest } from "store/actions/editEmail";
import FileSaver from "file-saver";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactHtmlParser from "react-html-parser";


export function* getPipelineApplications({ payload }) {
  try {
    const response = yield call(API.getPipelineApplications, payload);
    yield put(ACTION.receiveGetPipelineApplications(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* scheduleInterview({ payload }) {
  try {
    yield put(ACTION.moveCandidatesToAnotherStageReceive(null));

    yield call(API.scheduleInterview, payload);
    yield put(ACTION.moveCandidatesToAnotherStageReceive(true));

    const stage_id = localStorage.getItem("active_stage_id");
    yield put(
      ACTION.requestPipelineStages(
        History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id")
      )
    );
    yield put(
      getTemplateEmailRequest({
        template_type: "interview",
        interview_type: Number(3),
        source: "api",
        application_id: payload.data.candidate_application_id,
        job_request_id: payload.job_request_id,
        rescheduleTime:
          ("0" + new Date().getHours()).slice(-2) +
          ":" +
          ("0" + new Date().getMinutes()).slice(-2),
        rescheduleDate: new Date(),
        isScheduleType: true,
        interviewerName: null,
      })
    );
    yield put(
      ACTION.requestGetPipelineApplications({
        job_request_id:
          History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id"),
        params: {
          page_number: Number(History.location.search.split("=")[1]) || 1,
          stage_id: stage_id,
        },
      })
    );
    ReactGA.event({
      category: `${localStorage.getItem("domain")} : Schedule interviews`,
      action: "User requested schedule interviews",
    });

    showSnackbar("moveToInterviewGrid");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* checkAgentAvailability({ payload }) {
  try {
    yield put(ACTION.moveCandidatesToAnotherStageReceive(null));

    yield call(API.checkAgentAvailability, payload);
    yield call(API.scheduleInterview, payload);
    yield put(ACTION.moveCandidatesToAnotherStageReceive(true));

    const stage_id = localStorage.getItem("active_stage_id");
    yield put(
      ACTION.requestPipelineStages(
        History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id")
      )
    );
    // yield put(
    //   getTemplateEmailRequest({
    //     template_type: "interview",
    //     interview_type: Number(3),
    //     source: "api",
    //     application_id: payload.data.candidate_application_id?payload.data.candidate_application_id: null,
    //     job_request_id: payload.job_request_id,
    //     rescheduleTime:
    //       ("0" + new Date().getHours()).slice(-2) +
    //       ":" +
    //       ("0" + new Date().getMinutes()).slice(-2),
    //     rescheduleDate: new Date(),
    //     isScheduleType: true,
    //     interviewerName: null,
    //   })
    // );
    yield put(
      ACTION.requestGetPipelineApplications({
        job_request_id:
          History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id"),
        params: {
          page_number: Number(History.location.search.split("=")[1]) || 1,
          stage_id: stage_id,
        },
      })
    );
    ReactGA.event({
      category: `${localStorage.getItem("domain")} : Schedule interviews`,
      action: "User requested schedule interviews",
    });
    showSnackbar("moveToInterviewGrid");
  } catch (error) {
    dispatchError(error.response?.data);
    error.response.data.error.message.split(" ").slice(0, 2).join(" ") ===
      "The interviewer" && store.dispatch(ACTION.setErrRequest());
  }
}

export function* requestGetScheduleData({ payload }) {
  try {
    const response = yield call(API.requestGetScheduleData, payload);
    yield put(ACTION.receiveGetScheduleData(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* getApplicationFormPdfRequest({ payload }) {
  try {
    const response = yield call(API.getApplicationFormPdfRequest, payload);
    yield put(ACTION.getApplicationFormPdfRecieve(response.data));
    var blob = new Blob([ReactHtmlParser(response.data)], {
      type: "application/pdf",
    });
    FileSaver.saveAs(blob, "downloadedFileName.pdf");
    // pdf.save("downloadedFileName.pdf");
  } catch (error) {
    console.log(error);
  }
}

export function* requestRescheduleInterview({ payload }) {
  try {
    const response = yield call(API.reScheduleInterview, payload);
    yield put(ACTION.receiveRescheduleData(response.data));
    // if (History.location.pathname.split("/") === "myInterviews") {
    if (History.location.search.split("=")[2] === "new") {
      yield put(
        myInterviewsRequest({
          page_number: +History.location.search.split("=")[1].slice(0, 1) || 1,
          page_size: 10,
        })
      );
    } else {
      yield put(
        myPrevInterviewsRequest({
          page_number: +History.location.search.split("=")[1].slice(0, 1) || 1,
          page_size: 10,
        })
      );
    }
    // }
    showSnackbar("savedSuccessfuly");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestEditCandidatePermissions({ payload }) {
  try {
    const response = yield call(API.editCandidatePermissions, payload);
    yield put(ACTION.receiveEditCandidatePermissions(response.data));
    showSnackbar("savedSuccessfuly");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestEditJobDetailsPermissions({ payload }) {
  try {
    const response = yield call(API.editJobDetailsPermissions, payload);
    yield put(ACTION.receiveEditJobDetailsPermissions(response.data));
    showSnackbar("savedSuccessfuly");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* requestAddApplicants({ payload }) {
  try {
    const response = yield call(API.addApplicants, payload);
    showSnackbar("savedSuccessfuly");
    const stage_id = localStorage.getItem("active_stage_id");
    yield put(ACTION.receiveAddApplicants(response.data));
    store.dispatch(
      ACTION.requestPipelineStages(History.location.pathname.split("/")[2])
    );
    store.dispatch(
      ACTION.requestGetPipelineApplications({
        job_request_id: History.location.pathname.split("/")[2],
        params: {
          page_number: Number(History.location.search.split("=")[1]) || 1,
          stage_id: stage_id,
        },
      })
    );
    ReactGA.event({
      category: `${localStorage.getItem("domain")} : Add applicants`,
      action: "User requested add applicants ",
    });
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* getPipelineStages({ payload }) {
  try {
    const response = yield call(API.getPipelineStages, payload);
    yield put(ACTION.receivePipelineStages(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* requestRejectApplicants({ payload }) {
  try {
    yield put(ACTION.moveCandidatesToAnotherStageReceive(null));

    yield call(API.rejectApplicants, payload);
    yield put(ACTION.moveCandidatesToAnotherStageReceive(true));

    showSnackbar("done");
    if (History.location.pathname !== "/applicantProfile") {
      const stage_id = localStorage.getItem("active_stage_id");
      store.dispatch(
        ACTION.requestPipelineStages(
          History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id")
        )
      );
      store.dispatch(
        ACTION.requestGetPipelineApplications({
          job_request_id:
            History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id"),
          params: {
            page_number: Number(History.location.search.split("=")[1]) || 1,
            stage_id: stage_id,
          },
        })
      );
    }
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* requestMoveCandidateAnotherStage({ payload }) {
  try {
    // yield put(ACTION.moveCandidatesToAnotherStageReceive(null));
    const response = yield call(API.moveCandidatesToStages, payload);
    yield put(ACTION.moveCandidatesToAnotherStageReceive(response.data.data));
    yield put(
      requestApplicantMainData({
        job_request_id: getURLParams("job_request_id"),
        application_id: payload.data.candidate_application_id[0],
      })
    );

    showSnackbar("done");
    if (History.location.pathname !== "/applicantProfile") {
      const stage_id = localStorage.getItem("active_stage_id");
      store.dispatch(
        ACTION.requestPipelineStages(
          History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id")
        )
      );
      store.dispatch(
        ACTION.requestGetPipelineApplications({
          job_request_id:
            History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id"),
          params: {
            page_number: Number(History.location.search.split("=")[1]) || 1,
            stage_id: stage_id,
          },
        })
      );
    }
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* cancelInterviewRequest({ payload }) {
  try {
    // /manageCandidates/08dbaeb5-c173-493f-8e31-4cd2ea399ae0
    // yield put(ACTION.moveCandidatesToAnotherStageReceive(null));
    yield call(API.cancelInterviewRequest, payload);
    yield put(ACTION.moveCandidatesToAnotherStageReceive(true));
    showSnackbar("done");
    if (History.location.pathname === "/myInterviews") {
      yield put(
        myInterviewsRequest({
          page_number: +History.location.search.split("=")[1].slice(0, 1) || 1,
          page_size: 10,
        })
      );
    }
    if (History.location.pathname !== "/applicantProfile") {
      const stage_id = localStorage.getItem("active_stage_id");
      yield put(
        ACTION.requestPipelineStages(
          History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id")
        )
      );

      yield put(
        ACTION.requestGetPipelineApplications({
          job_request_id:
            History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id"),
          params: {
            page_number: Number(History.location.search.split("=")[1]) || 1,
            stage_id: stage_id,
          },
        })
      );
    }
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestAddRateToApplicants({ payload }) {
  try {
    yield call(API.addRateToApplicants, payload);
    showSnackbar("done");
    if (History.location.pathname === "/applicantProfile") {
      yield put(requestApplicantProfile(payload.candidate_id));
    } else {
      const stage_id = localStorage.getItem("active_stage_id");
      yield put(
        ACTION.requestPipelineStages(History.location.pathname.split("/")[2])
      );
      yield put(
        ACTION.requestGetPipelineApplications({
          job_request_id: History.location.pathname.split("/")[2],
          params: {
            page_number: Number(History.location.search.split("=")[1]) || 1,
            stage_id: stage_id,
          },
        })
      );
    }
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* requestPreofferData({ payload }) {
  try {
    const response = yield call(API.requestPreofferData, payload);
    yield put(ACTION.receivePreofferData(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* sendApplicantPreoffer({ payload }) {
  try {
    yield put(ACTION.moveCandidatesToAnotherStageReceive(null));
    yield call(API.sendApplicantPreoffer, payload);
    yield put(ACTION.moveCandidatesToAnotherStageReceive(true));
    showSnackbar("done");
    if (History.location.pathname !== "/applicantProfile") {
      const stage_id = localStorage.getItem("active_stage_id");
      yield put(
        ACTION.requestPipelineStages(
          History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id")
        )
      );
      yield put(
        ACTION.requestGetPipelineApplications({
          job_request_id:
            History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id"),
          params: {
            page_number: Number(History.location.search.split("=")[1]) || 1,
            stage_id: stage_id,
          },
        })
      );
    }
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* requestGetApplicantRate({ payload }) {
  try {
    const response = yield call(API.getApplicatRate, payload);
    yield put(ACTION.receiveGetApplicantRate(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* requestSendCustomizedEmail({ payload }) {
  try {
    yield call(API.sendCustomizedEmail, payload);
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestSendUpdateCVEmail({ payload }) {
  try {
    yield call(API.sendUpdateCVEmail, payload);
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* requestAddApplicantToAnotherJob({ payload }) {
  try {
    yield call(API.addToAnotherJobRequest, payload);
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* requestDisableAddApplicants() {
  try {
    const response = yield call(API.disableAddApplicants);
    yield put(ACTION.receiveDisableAddApplicants(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* requestDisableEngagement() {
  try {
    const response = yield call(API.disableEngagement);
    yield put(ACTION.receiveDisableEngagement(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* requestAddMoreApplicantInfo({ payload }) {
  try {
    const response = yield call(API.AddMoreApplicantInfo, payload);
    yield put(ACTION.receiveAddMoreApplicantInfo(response.data));
    showSnackbar("done");
    if (History.location.pathname !== "/applicantProfile") {
      const stage_id = localStorage.getItem("active_stage_id");
      yield put(
        ACTION.requestPipelineStages(
          History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id")
        )
      );
      yield put(
        ACTION.requestGetPipelineApplications({
          job_request_id:
            History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id"),
          params: {
            page_number: Number(History.location.search.split("=")[1]) || 1,
            stage_id: stage_id,
          },
        })
      );
    }
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestChangeStageSendEmail({ payload }) {
  try {
    yield put(ACTION.moveCandidatesToAnotherStageReceive(null));

    yield call(API.SendEmailChangeStage, {
      data: {
        stage_id: payload.stage_id,
        email_type: 2,
        subject: payload.subject,
        body: payload.body,
        title: "send email",
      },
      job_request_id:
        History.location.pathname.split("/")[2] ||
        getURLParams("job_request_id"),
      application_id: payload.candidate_list[0].application_id,
    });
    const stage_id = localStorage.getItem("active_stage_id");
    yield put(
      ACTION.requestPipelineStages(
        History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id")
      )
    );
    yield put(
      ACTION.requestGetPipelineApplications({
        job_request_id:
          History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id"),
        params: {
          page_number: Number(History.location.search.split("=")[1]) || 1,
          stage_id: stage_id,
        },
      })
    );
    yield put(ACTION.moveCandidatesToAnotherStageReceive(true));
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestGetOfferTemplate({ payload }) {
  try {
    const response = yield call(API.getSendOfferTemplate, {
      job_request_id:
        History.location.pathname.split("/")[2] ||
        getURLParams("job_request_id"),
      application_id: payload.application_id,
      basic_salary: payload.basic_salary,
      show_allowance_as_percentage: payload.show_allowance_as_percentage,
      candidate_nationality_id: payload.candidate_nationality_id,
    });
    yield put(ACTION.receiveGetSendOfferTemplate(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* requestOfferSalaryDetails({ payload }) {
  try {
    const response = yield call(API.getOfferSalaryDetails, {
      job_request_id:
        History.location.pathname.split("/")[2] ||
        getURLParams("job_request_id"),
      application_id: payload.application_id,
      candidate_nationality_id: payload.candidate_nationality_id,
    });
    yield put(ACTION.recieveOfferSalaryDetails(response.data.data));
  } catch (error) {
    dispatchError(error?.response?.data);
  }
}
export function* requestGetContractTemplate({ payload }) {
  try {
    const response = yield call(API.getSendContractTemplate, {
      job_request_id:
        History.location.pathname.split("/")[2] ||
        getURLParams("job_request_id"),
      application_id: payload,
    });
    yield put(ACTION.receiveGetSendContractTemplate(response.data.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}
export function* requestSendOfferTemplate({ payload }) {
  try {
    yield put(ACTION.moveCandidatesToAnotherStageReceive(null));

    const response = yield call(
      payload.isReoffer ? API.sendReoffer : API.sendOffer,
      {
        ...payload,
        job_request_id:
          History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id"),
      }
    );
    yield put(ACTION.moveCandidatesToAnotherStageReceive(true));

    const stage_id = localStorage.getItem("active_stage_id");
    yield put(
      ACTION.requestPipelineStages(
        History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id")
      )
    );
    yield put(
      ACTION.requestGetPipelineApplications({
        job_request_id:
          History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id"),
        params: {
          page_number: Number(History.location.search.split("=")[1]) || 1,
          stage_id: stage_id,
        },
      })
    );
    showSnackbar("done");
  } catch (error) {
    dispatchError(error?.response?.data);
  }
}
export function* requestSendContractTemplate({ payload }) {
  try {
    yield put(ACTION.moveCandidatesToAnotherStageReceive(null));

    yield call(payload.isReContract ? API.sendReContract : API.sendContract, {
      ...payload,
      job_request_id:
        History.location.pathname.split("/")[2] ||
        getURLParams("job_request_id"),
    });
    yield put(ACTION.moveCandidatesToAnotherStageReceive(true));

    const stage_id = localStorage.getItem("active_stage_id");
    yield put(
      ACTION.requestPipelineStages(
        History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id")
      )
    );
    yield put(
      ACTION.requestGetPipelineApplications({
        job_request_id:
          History.location.pathname.split("/")[2] ||
          getURLParams("job_request_id"),
        params: {
          page_number: Number(History.location.search.split("=")[1]) || 1,
          stage_id: stage_id,
        },
      })
    );
    showSnackbar("done");
  } catch (error) {
    dispatchError(error?.response?.data);
  }
}

export function* requestGetApprovalSetup(payload) {
  try {
    const response = yield call(API.getOfferApprovalsSetup, payload);
    yield put(ACTION.receiveGetOfferApprovalsSetup(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* requesContracttGetApprovalSetup({ payload }) {
  try {
    const response = yield call(API.getContractApprovalsSetup, payload);
    yield put(ACTION.receiveGetContractApprovalsSetup(response.data.data));
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export function* requestCandidatePermissins({ payload }) {
  try {
    const response = yield call(API.getCandidatePermissions, payload);
    yield put(ACTION.candidatePermissionRecieve(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* requestJobDetailsPermissins({ payload }) {
  try {
    const response = yield call(API.getJobDetailsPermissions, payload);
    yield put(ACTION.jobDetailsPermissionRecieve(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* requestGetApprovalStatusList({ payload }) {
  try {
    const response = yield call(API.getOfferApprovalStatusList, payload);
    yield put(ACTION.receiveGetOfferApprovalStatusList(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* requestDemo({ payload }) {
  try {
    const response = yield call(API.requestDemo, payload);
    showSnackbar("done");
    yield put(ACTION.requestDemoModal(false));
  } catch (error) {
    console.log(error);
  }
}
export function* requestFavoriteCandidate({ payload }) {
  try {
    yield call(API.requestFavoriteCandidate, payload.candidate_id);
    if (payload.type === "talent_pool") {
      yield put(candidateTalentPoolRequest(payload.nextRequestCandidatesList));
    }
    if (payload.type === "application_profile") {
      yield put(requestApplicantProfile(payload.candidate_id));
    }
    if (payload.type === "interview_quick_view") {
      yield put(
        interviewDetailsRequest({
          candidate_id: payload.candidate_id,
          interview_id: payload.interviewDetails.interview_id,
        })
      );
    }
    if (payload.type === "manage_candidates") {
      const { job_request_id, page_number, stage_id, filters } =
        payload.nextRequestCandidatesList;

      yield put(
        ACTION.requestGetPipelineApplications({
          job_request_id,
          params: {
            ...filters,
            page_number,
            stage_id,
          },
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}
export function* requestContractGetApprovalStatusList({ payload }) {
  try {
    const response = yield call(API.getContractApprovalStatusList, payload);
    yield put(ACTION.receiveGetContractApprovalStatusList(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* requestMeetingPlatforms({ payload }) {
  try {
    const response = yield call(API.getMeetingPlatforms, payload);
    yield put(ACTION.receiveMeetingPlatforms(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* ManageCandidateSagasWatch() {
  yield takeLatest(type.SCHEDULE_INTERVIEW_REQUEST, scheduleInterview);

  yield takeLatest(
    type.CHECK_AGENT_AVAILABILITY_REQUEST,
    checkAgentAvailability
  );

  yield takeLatest(
    type.REQUEST_GET_PIPELINE_APPLICATIONS,
    getPipelineApplications
  );
  yield takeLatest(type.REQUEST_GET_RESCHEDULE_DATA, requestGetScheduleData);
  yield takeLatest(
    type.REQUEST_RESCHEDULE_INTERVIEW,
    requestRescheduleInterview
  );
  yield takeLatest(type.REQUEST_ADD_APPLICANTS, requestAddApplicants);
  yield takeLatest(type.REQUEST_GET_PIPELINE_STAGES, getPipelineStages);
  yield takeLatest(type.REQUEST_REJECT_APPLICANTS, requestRejectApplicants);
  yield takeLatest(
    type.REQUEST_MOVE_APPLICANT_ANOTHER_STAGE,
    requestMoveCandidateAnotherStage
  );
  yield takeLatest(type.REQUEST_CANCEL_INTERVIEW, cancelInterviewRequest);

  yield takeLatest(type.REQUEST_PREOFFER_DATA, requestPreofferData);
  yield takeLatest(type.SEND_APPLICANT_PREOFFER_REQUEST, sendApplicantPreoffer);
  yield takeLatest(
    type.REQUEST_ADD_RATE_TO_APPLICANTS,
    requestAddRateToApplicants
  );
  yield takeLatest(type.REQUEST_GET_APPLICANT_RATE, requestGetApplicantRate);
  yield takeLatest(
    type.REQUEST_SEND_CUSTOMIZED_EMAIL,
    requestSendCustomizedEmail
  );
  yield takeLatest(type.REQUEST_SEND_UPDATE_CV_EMAIL, requestSendUpdateCVEmail);
  yield takeLatest(
    type.ADD_APPLICANT_TO_ANOTHER_JOB_OPENING_REQUEST,
    requestAddApplicantToAnotherJob
  );
  yield takeLatest(
    type.DISABLE_ADD_APPLICANTS_REQUEST,
    requestDisableAddApplicants
  );
  yield takeLatest(type.DISABLE_ENGAGEMENT_REQUEST, requestDisableEngagement);
  yield takeLatest(
    type.REQUEST_ADD_APPLICANT_MORE_INFO,
    requestAddMoreApplicantInfo
  );
  yield takeLatest(
    type.REQUEST_CHANGE_STAGE_SEND_EMAIL,
    requestChangeStageSendEmail
  );
  yield takeLatest(
    type.GET_SEND_OFFER_TEMPLATE_REQUEST,
    requestGetOfferTemplate
  );
  yield takeLatest(
    type.GET_SEND_CONTRACT_TEMPLATE_REQUEST,
    requestGetContractTemplate
  );
  yield takeLatest(type.SEND_OFFER_REQUEST, requestSendOfferTemplate);
  yield takeLatest(type.SEND_CONTRACT_REQUEST, requestSendContractTemplate);
  yield takeLatest(
    type.GET_OFFER_APPROVALS_SETUP_REQUEST,
    requestGetApprovalSetup
  );
  yield takeLatest(
    type.GET_CONTRACT_APPROVALS_SETUP_REQUEST,
    requesContracttGetApprovalSetup
  );
  yield takeLatest(
    type.GET_OFFER_APPROVALS_STATUS_LIST_REQUEST,
    requestGetApprovalStatusList
  );
  yield takeLatest(
    type.GET_CONTRACT_APPROVALS_STATUS_LIST_REQUEST,
    requestContractGetApprovalStatusList
  );
  yield takeLatest(
    type.REQUEST_TOGGLE_FAVORITE_CANDIDATE,
    requestFavoriteCandidate
  );
  yield takeLatest(type.REQUEST_DEMO_ACTION, requestDemo);
  yield takeLatest(
    type.REQUEST_OFFER_SALARY_DETAILS,
    requestOfferSalaryDetails
  );
  yield takeLatest(
    type.GET_CANDIDATE_PERMISSIONS_REQUEST,
    requestCandidatePermissins
  );
  yield takeLatest(
    type.REQUEST_EDIT_CANDIDATE_PERMISSIONS,
    requestEditCandidatePermissions
  );

  yield takeLatest(
    type.GET_JOB_DETAILS_PERMISSIONS_REQUEST,
    requestJobDetailsPermissins
  );
  yield takeLatest(
    type.REQUEST_EDIT_JOB_DETAILS_PERMISSIONS,
    requestEditJobDetailsPermissions
  );

  yield takeLatest(type.REQUEST_MEETING_PLATFORMS, requestMeetingPlatforms);
  yield takeLatest(
    type.APPLICATION_FORM_PDF_REQUEST,
    getApplicationFormPdfRequest
  );
}
