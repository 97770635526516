import React from "react";

export const Login = React.lazy(() => import("../containers/auth/Login/Login"));
export const RedirectLogin = React.lazy(() =>
  import("../containers/auth/Login/RedirectLogin")
);

export const ForgetPassword = React.lazy(() =>
  import("../containers/auth/ForgetPassword/ForgetPassword")
);
export const ResetPassword = React.lazy(() =>
  import("../containers/auth/ResetPassword/ResetPassword")
);
export const Success = React.lazy(() =>
  import("../containers/auth/Success/Success")
);
export const Home = React.lazy(() =>
  import("../containers/dashboard/MainLayout/MainLayout")
);
export const Setup = React.lazy(() =>
  import("../containers/dashboard/Setup/Setup")
);
export const CompanySetup = React.lazy(() =>
  import("../containers/dashboard/CompanySetup/CompanySetup")
);
export const EditCompanyDetails = React.lazy(() =>
  import("../containers/dashboard/CompanySetup/EditCompanyDetails")
);
export const AddEditBranches = React.lazy(() =>
  import("../containers/dashboard/AddEditBranches/AddEditBranches")
);
export const AddEditDigitalDocuments = React.lazy(() =>
  import("../containers/dashboard/DigitalDocuments/AddEditDigitalDocuments")
);
export const DashboardSetup = React.lazy(() =>
  import("../containers/dashboard/DashboardSetup/DashboardSetup")
);
export const JobRole = React.lazy(() =>
  import("../containers/dashboard/JobRole/JobRole")
);
export const Integration = React.lazy(() =>
  import("../containers/dashboard/Integration/Integration")
);
export const AddEditUnifonicIntegration = React.lazy(() =>
  import("../containers/dashboard/Integration/AddEditUnifonic")
);
export const AddEditOffice365Integration = React.lazy(() =>
  import("../containers/dashboard/Integration/AddEditOffice365")
);
export const AddEditJobRole = React.lazy(() =>
  import("../containers/dashboard/JobRole/AddEditJobRole")
);
export const ViewAgents = React.lazy(() =>
  import("../containers/dashboard/AgentSetup/AgentSetup")
);
export const AddEditAgents = React.lazy(() =>
  import("../containers/dashboard/AgentSetup/AddEditAgents")
);
export const addAgentPassword = React.lazy(() =>
  import("../containers/dashboard/AgentSetup/addAgentPassword")
);
export const ViewSalaryGrades = React.lazy(() =>
  import("../containers/dashboard/SalaryGradeSetup/SalaryGradeSetup")
);
export const AddEditSalaryGrades = React.lazy(() =>
  import("../containers/dashboard/SalaryGradeSetup/AddEditGradeSetup")
);
export const JobRequisitionSetup = React.lazy(() =>
  import("../containers/dashboard/JobRequisitionSetup/JobRequisitionSetup")
);
export const JobTitlesSetup = React.lazy(() =>
  import("../containers/dashboard/JobTitlesSetup/JobTitlesSetup")
);
export const AddEditJobTitle = React.lazy(() =>
  import("../containers/dashboard/JobTitlesSetup/AddEditJobTitles")
);
export const ManpowerRequests = React.lazy(() =>
  import("../containers/dashboard/ManpowerRequestsSetup/ManpowerRequestsSetup")
);
export const ViewAllRequests = React.lazy(() =>
  import("../containers/dashboard/ViewAllRequestsSetup/ViewAllRequestsSetup")
);
export const AdvertisementSetup = React.lazy(() =>
  import("../containers/dashboard/AdvertisementSetup/AdvertisementSetup")
);
export const AgentProfile = React.lazy(() =>
  import("../containers/dashboard/AgentProfile/AgentProfile")
);
export const AgentChangePassword = React.lazy(() =>
  import("../containers/dashboard/AgentProfile/AgentChangePassword")
);
export const ManageScreeningQuestions = React.lazy(() =>
  import("../containers/dashboard/ManageScreening/ManageScreening")
);
export const AdvertisementPreview = React.lazy(() =>
  import("../containers/dashboard/AdvertisementPreview/AdvertisementPreview")
);
export const viewJobSummary = React.lazy(() =>
  import("../containers/dashboard/ViewJobSummary/ViewJobSummary")
);
export const manageCandidates = React.lazy(() =>
  import("../containers/dashboard/ManageCandidates/ManageCandidates")
);
export const JobOpenings = React.lazy(() =>
  import("../containers/dashboard/ViewAllJobOpenings/ViewAllJobOpenings")
);
export const JobOpeningsArchive = React.lazy(() =>
  import("../containers/dashboard/ViewAllJobOpenings/ViewArchiveJobOpenings")
);
export const CandidatePortalSetup = React.lazy(() =>
  import("../containers/dashboard/CandidatePortalSetup/CandidatePortalSetup")
);
export const MyInterviews = React.lazy(() =>
  import("../containers/dashboard/MyInterviews/MyInterviews")
);
export const Reminders = React.lazy(() =>
  import("../containers/dashboard/Reminders/Reminders")
);

export const VideoInterview = React.lazy(() =>
  import("../containers/dashboard/VideoInterviewPage/VideoInterviewPage")
);
export const Requests = React.lazy(() =>
  import("../containers/dashboard/Requests/Requests")
);
export const RequestDetails = React.lazy(() =>
  import("../containers/dashboard/RequestDetails/RequestDetails")
);
export const ApplicantProfile = React.lazy(() =>
  import("../containers/dashboard/ApplicantProfile/ApplicantProfile")
);
export const PipelineSetup = React.lazy(() =>
  import("../containers/dashboard/PipelineSetup/AddEditFlow/AddEditFlow")
);
export const PipelineFlowsGrid = React.lazy(() =>
  import("../containers/dashboard/PipelineSetup/Flows/Flows")
);
export const ApplicantInterviews = React.lazy(() =>
  import(
    "../containers/dashboard/ApplicantProfile/ApplicantInterviews/ApplicantInterviews"
  )
);
export const ApplicantScreening = React.lazy(() =>
  import("../components/ApplicantProfile/ScreeningTab/ScreeningTab")
);
export const CardsSetup = React.lazy(() =>
  import("../containers/dashboard/PaymentSetup/SavedCardsList/SavedCardsList")
);
export const CheckoutStatus = React.lazy(() =>
  import("../containers/dashboard/PaymentSetup/CheckoutStatus/CheckoutStatus")
);
export const SubscriptionPlans = React.lazy(() =>
  import(
    "../containers/dashboard/PaymentSetup/SubscriptionPlans/SubscriptionPlans"
  )
);

export const OfferTemplateSettings = React.lazy(() =>
  import("../containers/dashboard/OfferTemplateSettings/OfferTemplateSettings")
);

export const Invoice = React.lazy(() =>
  import("../containers/dashboard/Invoice")
);

export const CandidateApprovals = React.lazy(() =>
  import(
    "../containers/dashboard/Approvals/CandidateApprovals/CandidateApprovals"
  )
);
export const CandidateTalentPool = React.lazy(() =>
  import("../containers/dashboard/CandidateTalentPool/CandidateTalentPool.js")
);
export const OrganizationalStructure = React.lazy(() =>
  import(
    "../containers/dashboard/OrganizationalStructure/OrganizationalStructure.js"
  )
);
export const PositionChart = React.lazy(() =>
  import("../containers/dashboard/PositionChart/PositionChart")
);
export const NotFound = React.lazy(() =>
  import("../components/NotFound/NotFound")
);
export const Invoices = React.lazy(() =>
  import("../containers/dashboard/InvoicesList/InvoicesList")
);
export const EmailTemplates = React.lazy(() =>
  import("../containers/dashboard/EmailTemplatesSetup/EmailTemplatesSetup")
);
export const RejectionReasons = React.lazy(() =>
  import("../containers/dashboard/RejectionReasons/RejectionReasonsList")
);
export const VacationBalances = React.lazy(() =>
  import("../containers/dashboard/VacationBalances/VacationBalancesList")
);
export const TicketsList = React.lazy(() =>
  import("../containers/dashboard/Tickets/TicketsList")
);
export const AddTicket = React.lazy(() =>
  import("../containers/dashboard/Tickets/AddTicket")
);
export const ContractTemplate = React.lazy(() =>
  import("../containers/dashboard/ContractTemplate/ContractTemplate")
);
export const AddEditRejectionReason = React.lazy(() =>
  import("../containers/dashboard/RejectionReasons/AddEditRejectionReason")
);

export const AddEditVacationBalances = React.lazy(() =>
  import("../containers/dashboard/VacationBalances/AddEditVacationBalances")
);
export const CreateEmailTemplate = React.lazy(() =>
  import("../containers/dashboard/EmailTemplateAdd/EmailTemplateAdd")
);
export const Report = React.lazy(() => import("../containers/Report/Report"));
export const NotificationList = React.lazy(() =>
  import("../containers/NotificationList/NotificationList")
);

export const ApprovalSetup = React.lazy(() =>
  import("../containers/dashboard/ApprovalSetup/ApprovalSetup")
);
export const RecruitmentPlan = React.lazy(() =>
  import("../containers/dashboard/RecruitmentPlan/RecruitmentPlan")
);

export const ManageCandidateProfile = React.lazy(() =>
  import(
    "../containers/dashboard/ManageCandidateProfile/ManageCandidateProfile"
  )
);

export const ManageJobDetails = React.lazy(() =>
  import("../containers/dashboard/ManageJobDetails/ManageJobDetails")
);
export const CandidateProfileSetup = React.lazy(() =>
  import("../containers/dashboard/CandidateProfileSetup/CandidateProfileSetup")
);

export const InterviewEvaluationForm = React.lazy(() =>
  import(
    "../containers/dashboard/InterviewEvaluationForm/InterviewEvaluationForm"
  )
);

export const Competences = React.lazy(() =>
  import("../containers/dashboard/Competences/CompetencesSetting")
);
export const DigitalDocuments = React.lazy(() =>
  import("../containers/dashboard/DigitalDocuments/DigitalDocuments")
);

export const PreOfferDocsList = React.lazy(() =>
  import("../containers/dashboard/PreOfferSetup/PreOffersDocsList")
);

export const AddEditPreOfferDocs = React.lazy(() =>
  import("../containers/dashboard/PreOfferSetup/AddEditPreOfferDocs")
);
