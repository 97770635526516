export const EDIT_TICKET_REQUEST = "EDIT_TICKET_REQUEST";
export const EDIT_TICKET_RECIEVE = "EDIT_TICKET_RECIEVE";

export const GET_SINGLE_TICKET_REQUEST = "GET_SINGLE_TICKET_REQUEST";
export const GET_SINGLE_TICKET_RECIEVE = "GET_SINGLE_TICKET_RECIEVE";

export const ADD_TICKETS_REQUEST = "ADD_TICKETS_REQUEST";
export const ADD_TICKETS_RECIEVE = "ADD_TICKETS_RECIEVE";

export const DELETE_TICKETS_REQUEST = "DELETE_TICKETS_REQUEST";
export const DELETE_TICKETS_RECIEVE = "DELETE_TICKETS_RECIEVE";

export const GET_ALL_TICKETS_RECIEVE = "GET_ALL_TICKETS_RECIEVE";
export const GET_ALL_TICKETS_REQUEST = "GET_ALL_TICKETS_REQUEST";
