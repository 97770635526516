export default {
  en: {
    home: "Home",
    English: "English",
    Arabic: "Arabic",
    chooseLanguage: "Choose language",
    langBtn: "عربى",
    signIn: "Login",
    signUp: "Sign up",
    userName: "Your Name",
    email: "Email Address",
    startChat: "Start Chat",
    jobPortal: "Jobs Portal",
    total: "Total",
    chatMessage: "Type Your Message",
    editEmail: "Edit email template",
    addAttachmentToList: "Save new docs. to the list",
    sameTemplate: "Yes, save to the same template",
    deleteTemplate: "Delete template",
    deleteTemplateApprove: "Are you sure you want to delete this template?",
    all: "All",
    linkCopied: "Link Copied",
    form_controls: {
      first_name: "First name",
      last_name: "Last name",
      email: "Email",
      company_name_ar: "Company Name (Ar)",
      company_name_en: "Company Name (En)",
      vat_num: "Vat Number",
      websiteLink: "Website URL",
      domain: "Domain",
      password: "Password",
      confirm_password: "Confirm password",
      phone: "Phone",
      current_password: "Current password",
      job_role: "Job role",
      company_size: "Company size",
      company_Logo: "Company Logo",
      company_Stamp: "Company Stamp",
      company_type: "Company type",
      comment: "Comment",
      requestDemo: "Request demo",
      recuitBefore: "Have you ever used a digital recruitment system?",
      yes: "Yes",
      no: "No",
      systemName: "System name",
    },
    verify: {
      title:
        "Verify your account to take this action and get full access to HRCOM",
      content: "Didn't receive an email?",
      action: "Resend",
    },
    candidateProfileSetup: {
      autoPublish: "Auto Publish",
      CandidatePortal: "Candidate Portal",
      qrcode: "(QRCode)",
      of: "of",
      simpleProfile: "Simple profile",
      complicatedProfile: "Complicated profile",
      uploadCVMode: "CV Upload",
      resetColor: "Apply default colors",
      resetLogo: "Apply default image",
      appliedBy: "Applied by CV Upload",
      uploadVideo: "Upload one video",
      video: "video",
      images: "Images",
      MaxImages: "Max. 5 images & 1 video",
      UploadCover: "Upload Images & Video",
      findYourDreamJob: "Find your dream job",
      jobsTitle: "Jobs title",
      jobsTitleEn: "Jobs title (EN)",
      jobsTitleAr: "Jobs title (AR)",
      UploadJobDetailsImage: "Upload Job details image",
    },
    approvalStage: {
      "Approval stage": "Approval stage",
      "Job details": "Job details",
      "Job requisition": "Job requisition",
      Summary: "Summary",
    },
    rejectionReason: {
      nameEn: "Reason (EN)",
      nameAr: "Reason (AR)",
      creationDate: "Creation date",
      addRejectionReason: "Create reason",
      editRejectionReason: "Edit Rejection Reason",
      creatorName: "Created By",
      deleteReasonContent: "Are you sure you want to delete this reason?",
      deleteReasonHeader: "Delete Rejection Reason",
    },
    vacationBalance: {
      vacationbalance: "Vacation balance",
      addnew: "Add new",
      deleteContent: "Are you sure you want to delete this balances?",
      deleteHeader: "Delete Vacation Balances",
      addVacationbalance: "Create Vacation balance",
      editVacationbalance: "Edit Vacation balance",
    },
    tickets: {
      tickets: "Ticket",
      addnew: "Add new",
      deleteContent: "Are you sure you want to delete this Ticket?",
      deleteHeader: "Delete Tickets",
      addTickets: "Create Ticket",
      editTickets: "Edit Ticket",
      ticketsCount: "Tickets Count",
    },
    generalSettings: {
      vacationBalances: "Vacation Balances",
      tickets: "Tickets",
      contractTemplate: "Contract template",
      sendContract: "Send Contract",
      CandidateProfileSetup: "Candidate Profile Setup",
      generalSettings: "General settings",
      uploadLogoTitle: "Upload image*",
      uploadLogoAction: "Upload image",
      uploadStampAction: "Upload Company Stamp",
      company: "Company",
      agent: "Agent",
      jobRole: "Job role",
      recruitment: "Recruitment",
      digitalDocuments: " Digital Documents",
      NewcomerAnnouncementEmail: "New comer Announcement Email",
      SendBefore: "Send Before",
      daysoftheJoiningdate: "days of the Joining date ",
      jobTitle: "Job title",
      reminders: "Reminders",
      salaryGrade: "Job grade",
      jobRequesition: "Job requisition",
      advertisement: "Advertisement",
      candidatePortalSetup: "Portal setup",
      pipelineSetupTitle: "Pipeline setup",
      subscriptionSettings: "Subscription settings",
      subscriptionPlans: "Subscriptions plans",
      savedCards: "Saved cards",
      offerTemplate: "Offer template",
      addPrimaryColor: "Primary Color",
      addSecondaryColor: "Secondary Color",
      integrations: "Integrations",
      library: "Library",
      emailTemplates: "Email templates",
      rejectionReasons: "Rejection reasons",
      addRejectionReason: "Create reason",
      PreofferDocuments: "Pre-offer Documents",
      editRejectionReason: "Edit Rejection Reason",
      contractTemplate: "Contract Template",
      interviewForm: "Interview evaluation form",
      interviewEvaluationForm: "Interview Evaluation Form",
    },
    emailTemplates: {
      BacktoDefaultTemplate: "Back to Default Template",
      add: "Create email template",
      edit: "Edit",
      templateNameAr: "Template Name AR",
      templateNameEn: "Template Name EN",
      subject: "Subject",
      body: "Body",
      delete: "Are you sure you want to delete this template?",
      deleteHeader: "Delete Template",
      maxSizeImage:
        "*Please, make sure if you include an image it will not exceed 2MB",
    },
    errors: {
      failedToFetch: "Sorry, Process failed. Kindly retry again!",
      required: "This field is required",
      requiredFile: "Upload File is required",
      inactive: "The integration is already deactivate",
      validPhone: "Invalid Phone Number",
      validEmail: "Invalid email",
      validDomain:
        "Allow English only, spacing and special characters are not allowed",
      validPassoword_title_tip: "Password is following these rules :",
      validURL: "Please enter a valid website URL (e.g. https://hrcom.io/)",
      validPassord_min_tip: "Min. 8 characters",
      validPassword_mix: "A mixture of both uppercase and lowercase letters",
      validPassword_letters:
        "A mixture of letters, numbers and special characters",
      validPassword:
        "Invalid password : Min 8 characters , a mixture of uppercase and lowercase letter and numbers  and special characters",
      validMatching: "Password and confirm password don’t match",
      imageMaxSize: "Image should not exceed 10 MB",
      imageMaxSize5: "Image should not exceed 5 MB",

      imagetype: "Png and JPEG are only allowed",
      maxFieldsWeightSum:
        "The fields total weight must be less than or equal 100%",
      minMaxAgeDiff: "The minimum age must be less than the maximum age",
      repeatedLangWithProficiency:
        "You can't repeat the language with its proficiency level",
      repeatedCollegeWithDegree: "You can't repeat the college with its degree",
      repeatedInstitutegeWithDegree: "You can't repeat the Institute/School",
      repeatedWorkExperience: "You can't repeat the work experience",
      validNumber: "Please enter a valid number",
      validMinDate: "Date should not be before minimal date",
      validDate: "Please enter a valid date",
      maxWeight: "Max 100",
      minWeight: "Min 100",
      selectOnePreoffer: "Please select at least one document",
      invalidNumber: "Invalid number",
      minMaxSalary: "Maximum salary should be bigger than Minimum",
      medMaxSalary:
        "Medium salary should be more than Minimum and less than Maximum",
      salaryValidation:
        "It only accepts numbers and maximum of 2 decimals are allowed",
      invalidGPAValue: "It should be between 0 - 4",
      closingDaysErr: "Min. one day",
      requiredShort: "Required",
      requiredSectionFieldsWeights:
        "*You have to put weight for at least one field",
      requiredQuestionWeight:
        "*You have to put weight for at least one question",
      requiredAnswersWeight: "*You have to put weight for at least one answer",
      requiredWeight: "Weight must be greater than 0",
      dateIntersection: "The date answers must not intersect with each other",
      timeIntersection: "The time answers must not intersect with each other",
      toGreaterThanFrom: "To must be greater than from",
      fileMaxSize: "File should not exceed 3 MB",
      fileType: "PDF, Word, PNG, JPEG are only allowed",
      FileMaxSize5MB: "File size should not exceed 10MB",
      customFileMaxSize: "File size should not exceed {maxSize}MB",
      pdfOnly: "PDF is only allowed",
      evaluationFormFileType: "PDF, Word, Xls, PNG, JPEG, JPG are only allowed",
      imageRatio: "The image ratio must be 1:1",
      wizardFileSizeErr: "Max size is 3MB",
      wizardFileTypeErr: "Excel files are only allowed",
      salaryTotalSum:
        "The sum of the salary sections have to be less than 100%",
      percentageGreaterThanZero: "Percentage must be greater than 0",
      deactivatedCompany:
        "This company is deactivated now, please contact HRcom team info@hrcom.io",
      timeInPast: "You can`t select time in past",
      CVfileType: "Word or PDF are only allowed",
      endTimeErr: "The interview should end after the start time",
      selectCandidate: "Please select candidate first",
      selectMultiCandidates: "Please select only one candidate",
      stageErrors: "Candidates selected must be in same stage",
      selectOnePreoffer: "Please select at least one document",
      deleteDefaultErr:
        "You can't delete the active card, Please active another card first",
      maxAgentsErr: "You've reached maximum number of agents on your plan",
      maxJobsErr:
        "You have reached the allowed number of job openings, upgrade to be able to manage the new job openings",
      maxCandidatesErr: "You've reached the maximum limit in your plan",
      maxVideosDuration: "All answers duration should be maximum 5 minutes",
      uniqueStageActionErr: "Can`t choose  this action more than one time",
      pastDate: "You can`t select date in past",
      maxLength100: "Max. 100 char.",
      maxLength1000: "Max. 1000 char.",
      maxLength30: "Max. 30 char.",
      maxLength20: "Max. length is 20.",
      numbersOnly: "Doesn't accept special characters",
      maxLength150: "Max. 150 char.",
      maxLength200: "Max. 200 char.",
      jobLimitationRemaining:
        "You are still able to post more # jobs this month.",
      oneFieldRequired: "At least one of the above field's values is required",
    },
    contact_us_title: "Contact us",
    contact_us_content:
      "For more information, fill out the form and we’ll be in touch.",
    approvalSetup: {
      approvals: "Approvals",
      noApprovals: "No approvals",
      actionLabel: "Select action",
      toApprovedBy: "to be approved by",
      addNewApproval: "Add new approval",
      approvalType: "Approval Type",
      jobTitle: "Add job title",
      jobRole: "Add job Role",
      note: "Please note that the request will be sent for approval by this order",
      errors: {
        stage_manpower: "Approval Stage required",
        approval_type: "Approval Type is Required",
        salary_grades: "Salary Grades is Required",
        salary_ranges: "Salary Ranges is Required",
        approved_by: "To be approved by Required",
        atLeastOneAction: "Fill Approval Actions Correctly",
      },
    },
    shared: {
      enable: "Enable",
      disable: "Disable",
      send_again: "Send again",
      firstViewTalentPool:
        "Click the button below to start building a strong talent pool to communicate with your applicants easily",
      phone: "Phone",
      address: "Address",
      submit: "Submit",
      message: "message",
      message_placeholder: "Your message here ..",
      register: "Register",
      registeryourCompany: "Register your company",
      fill_all_form: "*Please fill all fields",
      success_register:
        "A confirmation email has been sent, please check your email. If you didn't receive the confirmation email within 1 minute please presses Send again",
      login: "Sign in",
      forgetPassword: "Forgot password ?",
      forgotYourPassword: "Forgot your password",
      resetBtn: "Reset",
      resetTempBtn: "Reset",
      newPassword: "New password",
      confirmNewPassword: "confirm the new password",
      resetYourPass: "Reset your password",
      company_details: "Company Details",
      edit_company_details: "Edit Company Details",
      addAgentPassword: "Add your password",
      notFoundRecords: "No records found",
      changePassword: "Change password",
      firstViewJobs:
        "Click on the button below to create your first job request",
      logout: "Logout",
      copied: "Copied successfully",
      and: "and",
      am: "AM",
      pm: "PM",
      rate: "Rate",
      comment: "Comment",
      outOf100: "out of 100%",
      addMore: "Add more",
      label: "Label",
      notFoundCards: "There is no added cards",
      notFoundPage: "Oops, Sorry we can’t find this page",
      outOf: "Out of",
      compeleteProfileData: "please update your company profile",
      noDataFound: "No data found",
    },
    buttons: {
      share: "Share",
      add: "Add",
      remove: "Remove",
      evaluation: "Evaluation",
      downloadFiles: "Download files",
      skip: "Skip",
      saveAndSkip: "Skip & Save",
      next: "Next",
      loading: "Loading.....",
      rejectedOffer: "Sorry this offer is not available anymore",
      cancel: "Cancel",
      submit: "Submit",
      finish: "Finish",
      yes: "Yes",
      createJob: "Create a new job",
      no: "No",
      confirm: "Confirm",
      form: "Form",
      edit: "Edit",
      delete: "Delete",
      addNewBranch: "Add New Branch",
      uploadImage: "Upload image",
      save: "Save",
      save_new: "Save & add new",
      close: "Close",
      showMore: "view more",
      saveGoNext: "Save and go next",
      post: "Post",
      copyUrl: "Copy URL",
      socialMediaPermissions:
        "Allow Hrcom team to share your job through Jobs media",
      done: "Done",
      copyFromTemplate: "Copy from templates",
      uploadFile: "Upload file",
      view: "View",
      useACopy: "Copy",
      download: "Download",
      startInterview: "Start interview",
      finishInterview: "Finish interview",
      leaveInterview: "Leave Interview",
      evaluateInterview: "Evaluate",
      attachFile: "Attach file",
      attachment: "Attachment",

      attachPDF: "Upload PDF",
      pdf: "PDF",
      reupload: "Reupload",
      page: "Page",
      next: "Next",
      prev: "Previous",
      finish: "Finish",
      approve: "Approve",
      disApprove: "Disapprove",
      uploadExcel: "Import from excel",
      downloadSample: "Download sample",
      sendForApproval: "Send for approval",
      send: "Send",
      proceed: "Proceed",
      goBack: "Go back",
      apply: "Apply",
      print: "Print",
      cancelled: "Cancelled",
      applyDefaultTemplate: "Apply default template",
      addField: "Add field",
      reopen: "Reopen",
      signApprove: "Sign and approve",
      signContractApprove: "Sign and approve",
      reset: "Reset",
      duplicate: "Duplicate",
      search: "Search",
      resetSearch: "Clear all",
      filter: "Filter",
      applyFilter: "Apply",
      requestDemo: "Request demo",
      requestFreeDemo: "Request a free demo",
      saveAddArabicTemplate: "Save & add arabic template",
      saveAddEnglishTemplate: "Save & add english template",
    },
    branches: {
      addNewBranch: "Add New Branch",
      editBranch: "Edit Branch",
      branch_name: "Branch Name",
      address: "Address",
      country: "Country",
      city: "City",
      form_controls: {
        name_ar: "Branch Name (Ar)*",
        name_en: "Branch Name (En)*",
        country: "Country*",
        city: "City*",
        address_ar: "Address (Ar)*",
        address_en: "Address (En)*",
      },
    },
    confirmation: {
      cancel: "Are you sure you want to cancel your request?",
      cancelHeader: "Cancel Request",
      deleteBranchHeader: "Delete Branch",
      deleteBranchContent: "Are you sure you want to delete this branch?",
    },
    jobRoles: {
      SaveUpdate: "Save & Update",
      job_role_ar: "Job role (AR)",
      job_role_en: "Job role (EN)",
      assigedAgents: "No. of assigned agents",
      jobRolesRequired: "Job Role is Required",
      permissions: "Permissions",
      addNewJobRole: "Create new job role",
      assignedJobRole: "assigned users",
      editJobRole: "Edit job role",
      atLeastOnPermission: "The job role must have at least one permission",
      job_role: "Job Roles",
      deleteJobRoleTitle: "Delete job role",
      deleteJobRoleContent: "Are you sure you want to delete this job role?",
    },
    agents: {
      addNewAgent: "Add New Agent",
      editAgent: "Edit Agent",
      agentName: "Agent Name",
      agentEmail: "Agent email",
      agentRoles: "Agent role(s)",
      status: "Status",
      creationDate: "Creation date",
      deleteAgent: "Delete agent",
      deleteAgentContent:
        "Are you sure you want to remove this agent. Note that he/she will not be able to login to their account anymore?",
      ActivateAgent: "Are you sure you want to activate this agent?",
      DeactivateAgent: "Are you sure you want to deactivate this agent?",
      ActivateTitle: "Activate Agent",
      DeactivateTitle: "Deactivate Agent",
      activated: "Activated",
      deactivated: "Deactivated",
      agentProfile: "My profile",
      confirmed: "Verified",
      notConfirmed: "Not verified",
      verifyModalContent:
        "Are you sure you want to send verification email to this agent?",
      verifyModalHeader: "Send verification email",
      department: "Department",
      jobTitle: "Job Title",
      activeAgents: "Direct Manager",
    },
    sideMenu: {
      vacationBalances: "vacation Balances",
      interviewEvaluationForm: "Interview Evaluation Form",
      Competences: "Competences Settings",
      activejobs: "Active jobs",
      Archived: "Archived",
      ManageCandidateProfile: "Manage Candidate Profile",
      ManageJobDetails: "Manage Job Details",
      recruitmentPlan: "Recruitment Plan",
      Jobopening: "Job opening",
      ManpowerRequests: "Manpower requests",
      OrganizationalStructure: "Organizational Structure",
      positionChart: "Positions Chart",
      Organization: "Organization",
      Dashboard: "Dashboard",
      TalentPool: "Talent pool",
      Settings: "Settings",
      approvalSetup: "Approval Setup",
      ApprovalSetup: "Setup approval cycle",
      createNewRequest: "Create a new request",
      EditManpowerRequest: "Edit Manpower Request",
      viewAllrequests: "View all requests",
      Requests: "Approval request",
      candidateProfile: "Candidate profile",
      profile: "Profile",
      applicantProfile: "Candidate profile",
      RequestDetails: "Request details",
      CandidatePortalSetup: "Portal setup",
      interview: "Interview",
      myInterviews: "My interviews",
      jobOpenings: "Job openings",
      manageCandidates: "Manage candidates",
      viewjobSummary: "Job summary",
      advertisementPreview: "Advertisement preview",
      changePassword: "Change password",
      advertisementSetup: "Advertisement setup",
      viewAllRequests: "All requests",
      manpowerRequests: "Manpower requests",
      jobTitles: "Job titles",
      jobTitle: "Job titles",
      jobRequisitionSetup: "Job requisition setup",
      salaryGrade: "Salary grades",
      salaryGrades: "Job grades",
      agents: "Agents",
      agent: "Agents",
      jobRoles: "Job roles",
      jobRole: "Job roles",
      branch: "Company",
      companyEdit: "Company",
      companySetup: "Company",
      setup: "Settings",
      interviewQuestions: "Questions",
      interviewsSetup: "Interviews setup",
      pipelineFlows: "Pipeline setup",
      addPipelineFlow: "Pipeline setup",
      cardsSetup: "Subscription settings",
      subscriptionPlans: "Subscriptions plans",
      offerTemplate: "Offer template",
      candidatesRequests: "Candidates requests",
      agentsRequests: "Agents requests",
      invoices: "View Invoices",
      integrations: "Integrations",
      emailTemplates: "Email templates",
      createEmailTemplate: "Create email template",
      editEmailTemplate: "Edit email template",
      report: "Report",
      rejectionReasons: "Rejection reasons",
      cancellationReason: "Cancellation reason",
      cancelInterview: "Cancel Interview",
      addRejectionReason: "Add Rejection Reason",
      editRejectionReason: "Edit Rejection Reason",
      contractTemplate: "Contract Template",
      preOfferDocs: "Pre-offer Documents",
    },
    digitalDocuments: {
      AddNewDigitalDocument: "Add New Digital Document",
      DigitalDocument: "Digital Document",
      sendDigitalDocument: "Send Digital Document",
      editDigitalDocument: "Edit Digital Document",
      DigitalDocumentNameEn: "Digital Document Name (En)",
      DigitalDocumentNameAr: "Digital Document Name (Ar)",
      deleteDocumentContent: "Are you sure you want to delete this Document?",
      deleteDocumentHead: "Delete Digital Document ",
      arabic_template: "Arabic Template",
      english_template: "English Template",
    },
    preOfferDocs: {
      name_en: "Documents Name (EN)",
      name_ar: "Documents Name (AR)",
      creation_date: "Creation date",
      CreateDocument: "Create Document",
      CreateTitle: "Create Pre-offer Document",
      editPreOfferTitle: "Edit Pre-offer Document",
      deletePreOfferContent: "Are you sure you want to delete this document?",
      deletePreOfferTitle: "Delete Document",
    },
    salaryGrade: {
      salaray_grade_en: "Job grade Name (EN)",
      salaray_grade_ar: "Job grade Name (AR)",
      min: "Min.",
      max: "Max.",
      med: "Med.",
      currency: "Currency",
      addNewSalaryGrade: "Add a new grade",
      editSalaryGrade: "Edit salary grade",
      grade_name_ar: "Grade name (AR)",
      grade_name_en: "Grade name (EN)",
      min_salary: "Min. Salary",
      max_salary: "Max. Salary",
      med_salary: "Med. Salary",
      published_date: "Publishing date",
    },
    jobRequisitionSetup: {
      Editable: "Editable",
      jobRequisitionHeader: "Job requisition",
      companyBriefAR: "Company brief (AR)",
      companyBriefEN: "Company brief (EN)",
      industryName: "Company's industry",
      show: "Show",
      hide: "Hide",
      Mandatory: "Mandatory",
      withRate: "Requisition (Rate)",
      noRate: "Requisition (No rates)",
      noRateDescAr: "ٌRequisition (AR)",
      noRateDescEn: "Requisition (EN)",
      details: "Requisition details",
      noDetails: "Requisition",
      requisitionType: "Select requisition type",
      jobDescription: "Job Description",
      companyBrief: "Company brief",
    },
    recruitmentPlan: {
      title: "Update your recruitment plan",
      uploadBtn: "Import Jobs from excel",
      tooltipExcel: "Download the Excel sheet sample first",
      tooltipRemove: "Don't remove or add columns to the excel sheet",
    },
    jobTitles: {
      addNewGobTitleBtn: "Add A New Job Title",
      jobTitleAr: "Job Title (AR)",
      jobTitleEn: "Job Title (EN)",
      salaryGrade: "Salary grade",
      createNewJobTitleHeader: "Create new job title",
      editJobTitleHeader: "Edit job title",
      category: "Category",
      executiveDirector: "Executive Director of Shared Services",
    },
    jobDetailsStep: {
      reference_number: "Reference number",
      jobLocation: "Job Location",
      job_category: "Job status",
      replaced_employee: "Replaced employee",
      jobDetailsHeader: "Job details",
      jobTitle: "Job title",
      salaryGrade: "Job grade",
      basicSalary: "Basic salary",
      openings: "No. of openings",
      companyBranch: "Company branch",
      joiningDate: "Job closing date",
      jobType: "Job type",
      externalJob: "External",
      housing: "Housing",
      transportation: "Transportation",
      name: "Name",
      addJobTitle: "Add Job Title",
      addSalary: "Add salary",
      removeSalary: "Remove salary",
      department: "Department",
      value: "Value",
      mobileAllowance: "Mobile Allowance",
      percentage: "Percentage",
      budget: "Budget",
    },
    adsSetup: {
      adsHeader: "Advertisement Setup",
      adsTitle: "Title",
      adsDescription: "Description",
      adsImage: "Image",
      addField: "Add field",
    },
    manageJobRequisition: {
      sameTemplate: "Yes, save to the same template",
      manageJobRequisitionHeader: "Job Requisition",
      lessThanOrEqual: "Should be less than or equal 100",
      showInAdvertisement: "Show in Advertisement",
      preferred: "Preferred",
      disqualifier: "Disqualifier",
      equals: "equals",
      lessThan: "less than",
      greaterThan: "greater than",
      between: "between",
      min: "Min",
      max: "Max",
      age: "Age",
      proficiencyLevel: "Proficiency level",
      jobDescriptionAr: "Job description (Ar)",
      jobDescriptionEn: "Job description (En)",
      closingData: "Closing date",
      days: "days",
      yearsOfExp: "Years of experience",
      yearsLabel: "Years",
      fieldsLabel: "Industries",
      inTheField: "in the industry",
      collegeLabel: "College",
      InstituteSchool: "Institute/School",

      degreeLabel: "Degree",
      GPALabel: "GPA",
      Competencies: "Competencies",
      skillsAr: "Skills (Ar)",
      skillsEn: "Skills (En)",
      saveTemplateHeader: "Do you want to save this requisition as a template?",
      templateNameAr: "Template name (Ar)",
      templateNameEn: "Template name (En)",
      modalYesBtn: "Yes, save as a template",
      modalNoBtn: "No, save and go next",
      ageLevelLabel: "Level",
      copyTemplateConfirmModalContent:
        "Are you sure you want to load a saved requisition template? noting that your current data will be deleted",
      fileLabel: "File",
      descriptiveFileLabel: "Descriptive file",
      requisitionAr: "Job Description (Ar)",
      requisitionEn: "Job Description (En)",
      requisitionRate: "Requisition with rate",
      requisitionNoRate: "Requisition with no rate",
      contractType: "Contract type",
      fieldsOfStudy: "Field of study",
      jobNotPostableToDrJob:
        "This type of requisition will not allow you to share the job on Dr.Job",
    },
    manageScreeningQuestions: {
      section_title_ar: "Section title (AR)",
      section_title_en: "Section title (EN)",
      create_new_question: "Create new question",
      copy_question_from_library: "Copy from questions library",
      weight: "Weight%",
      question_type: "Type",
      preferred: "Preferred",
      disqualifier: "Disqualifier",
      question_ar: "Question (AR)",
      question_en: "Question (EN)",
      answer_ar: "Answer (AR)",
      answer_en: "Answer (EN)",
      section: "Section",
      question: "Question",
      addNewSection: "Add new section",
      operation: "Operation",
      type: "Type",
      date_from: "Date from",
      date_to: "Date to",
      date: "Date",
      time: "Time",
      time_from: "Time from",
      time_to: "Time to",
      disqualifierSelectTitle: "Select right answer",
      add: "Add",
      screeningTitle: "Screening",
      saveTemplateHeader: "Do you want to save this screening as a template?",
      copyTemplateConfirmModalContent:
        "Are you sure you want to load a saved screening? noting that your current data will be deleted",
      skipScreening: "Skip screening",
    },
    report: {
      Offers: "Offers",
      totalOffers: "Total of offers",
      totalContracts: "Total of contracts",
      Accepted: "Accepted",
      Rejected: "Rejected",
      Negotiation: "Negotiation",
      "Waiting for candidate": "Waiting for candidate",
      Pending: "Pending",
      monthlyHiring: "Monthly Hiring",
      Interviews: "Interviews",
      totalInterviews: "Total of interviews",
      "Face-to-face": "Face-to-face",
      Phone: "Phone",
      Video: "Video",
      addedByWebsite: "Applied through the website",
      addedManual: "Added Manually",
      website: "By website",
      manual: "Manually",
      totalCandidates: "Total of candidates",
      totalJobOpening: "Total of job openings",
      jobOpening: "Job Opening",
      "In progress": "In progress",
      Opened: "Opened",
      hold: "Hold",
      closed: "Closed",
      // Agents: "المستخدمين",
      assignedAgents: "Assigned Agents",
      "Total of agents": "Total of agents",
      jobStage: "Jobs stages",
      Activated: "Activated",
      Deactivated: "Deactivated",
      averageAgeOfJobs: "Average age of jobs",
      averageClosedJobsAge: "Average closed jobs age",
      averageOpenedJobsAge: "Average opened jobs age",
      Exportfile: "Export file",
      export: "Export",
      days: " Days",
      newJoiners: "New Joiners",
      week: "Week",
      month: "Month",
      quarter: "Quarter",
      current: "Current ",
      next: "Next ",
    },
    viewAllRequests: {
      jobSummary: "job Summary",
      Archive: "Archive",
      Retrieve: "Retrieve",
      requestId: "Request ID",
      jobTitles: "Job title",
      vacanciesNumber: "No. of vacancies",
      joiningDate: "Job closing date",
      state: "state",
      creationDate: "Creation date",
      aprpoveDate: "Approved on",
      disAprpoveDate: "Disapproved on",
      jobRequistion: "Job requisition",
      summary: "Summary",
      screening: "Screening",
      tabsInprogress: "In progress",
      tabsPublished: "Published",
      tabsClosed: "Closed",
      tabsHolded: "Hold",
      by: "By",
      deleteRequestModalHeader: "Delete request",
      deleteRequestModalContent:
        "Are you sure you want to delete this request?",
      inpogressRequests: "In progress Requests",
      publishedRequests: "Published Requests",
      closedRequests: "Closed Requests",
      holdRequests: "Hold Requests",
      publishingDate: "Publishing date",
      closingDate: "Closing date",
      branch: "Branch",
      country: "Country",
      city: "City",
      status: "Status",
      applicants: "Total Applicants",
      newApplicants: "New applicants",
      shortlisted: "Shortlisted",
      interviews: "Interviews (Completed/ Scheduled)",
      offers: "Offers",
      accepted: "Accepted",
      rejected: "Rejected",
      manageCandidates: "Manage candidates",
      changeClosingDate: "Change closing date",
      outOf: "Out of",
      waitingForApproval: "Waiting for approval",
      approvedRequest: "Approved",
      disapprovedRequest: "Disapproved",
      totalConsumedMinutes: "Total consumed minutes",
      closeJobModalContent: "Are you sure you want to close this job?",
      closeJobModalHeader: "Close job",
      holdJobRequest: "Hold",
      fillDate: "Filled Date",
      Filled: "Filled",
      partialFilled: "Partially Filled",
    },
    requestSummary: {
      summaryHeader: "Summary",
      advertisementPreviewBtn: "Advertisement Preview",
      publishPostConfirmModalHeader: "Publish job opening",
      publishPostConfirmModalContent:
        "Are you sure you want to publish the job opening?",
      companyBreifHeader: "Company brief",
      jobSpecsHeader: "Job specification",
      residencyHeader: "Residence Country",
      ResidencyCity: "Residence City",
      nationalityHeader: "Nationality",
      ageHeader: "Age",
      genderHeader: "Gender",
      MaritalStatusHeader: "Marital status",
      languageHeader: "Language",
      careerLevelHeader: "Career level",
      jobDescribtionHeader: "Job description",
      workExperienceHeader: "Work experience",
      educationHeader: "Education",
      competenciesHeader: "Competencies",
      skillsHeader: "Skills",
      weight: "Weight",
      answer: "Answer",
      noData: "No entered data",
      fileName: "File name",
    },
    Competencies: {
      LevelsSection: "Levels Section",
      remove: "Remove",
      nameValidation: "Name must not be empty",
      AddCompetenciesCategory: "Add  Competencies category",
      AddCompetenciesItem: "Add  Competencies Item",
      SaveandNew: "Save and New",
      item: "Item",
      AtleastOne: "At least one level is required",
    },
    advertisementPreview: {
      advertisementPreviewHeader: "Advertisement Preview",
      closeAdvertismentModalHeader: "Close the advertisement",
      closeAdvertismentModalContent:
        "Are you sure you want to close the advertisement? Noting that your edits will be deleted",
    },
    tabsHeader: {
      applicants: "Applicants",
      shortlisted: "Shortlisted",
      interview: "Interview",
      offers: "Offers",
      rejected: "Rejected",
      accepted: "Accepted",
      candidateInfo: "Candidate Info",
      cv: "CV",
      jobDetails: "Job Details",
    },
    manageCandidates: {
      Anotherinterview:
        "Another interview was found at the same time do you want to continue",
      ReferenceNo: "Reference No",
      ConnectVia: "Connect Via",
      age: "Age",
      yearsOfExperience: "Years Of Experience",
      name: "Name",
      email: "Email",
      phone: "Phone",
      gender: "Gender",
      appliedOn: "Applied on",
      reject: "Reject",
      offer: "Offer",
      notes: "Notes",
      scheduleInterview: "Schedule interview",
      informApplicant: "Inform Applicant",
      male: "Male",
      female: "Female",
      moveToShortList: "Move to shortlist",
      interviewDate: "Interview date",
      interviewStartTime: "Start",
      interviewEndTime: "End",
      interviewerName: "Interviewer name",
      organizerName: "Meeting organizer",
      schedule: "Schedule",
      candidateName: "Candidate name",
      status: "Status",
      rate: "Rate",
      reScheduleInterview: "Reschedule interview",
      pending: "Pending",
      interviewed: "interviewed",
      cancelInterview: "cancel Interview",
      rejectBy: "Rejected by",
      rejectOn: "Rejected on",
      rejectState: "Rejected state",
      viewReason: "View reason",
      rejectionReason: "Rejection reason",
      type: "Type",
      sendRegressionLetter: "Send regretting letter",
      sendRegressionLetterNow: "Now",
      sendRegressionLetterAfter5Days: "In 5 Days",
      sendRegressionLetterAfter10Days: "In 10 Days",
      expirationOfferTitle: "This offer should expire after",
      expirationOfferAfter3: "3 Days",
      expirationOfferAfter5: "5 Days",
      expirationOfferAfterNever: "Never",
      age: "Age",
      yearsOfExperience: "Years Of Experience",
      note: "Note",
      name: "Name",
      email: "Email",
      phone: "Phone",
      gender: "Gender",
      appliedOn: "Applied on",
      reject: "Reject",
      offer: "Offer",
      notes: "Notes",
      scheduleInterview: "Schedule interview",
      informApplicant: "Inform Applicant",
      male: "Male",
      female: "Female",
      moveToShortList: "Move to shortlist",
      interviewDate: "Interview date",
      interviewStartTime: "Start",
      interviewEndTime: "End",
      interviewerName: "Interviewer name",
      schedule: "Schedule",
      candidateName: "Candidate name",
      status: "Status",
      rate: "Rate",
      reScheduleInterview: "Reschedule interview",
      pending: "Pending",
      interviewed: "interviewed",
      rejectBy: "Rejected by",
      rejectOn: "Rejected on",
      rejectState: "Rejected state",
      viewReason: "View reason",
      rejectionReason: "Rejection reason",
      type: "Type",
      sendRegressionLetter: "Send regretting letter",
      interviewName: "Interview name",
      applicant: "Applicant",
      interviewType: "Interview type",
      video: "Video",
      faceToFace: "Face to face",
      location: "Location",
      subject: "Subject",
      body: "Body",
      title: "Title",
      editEmailURLS: "URLS",
      viewEmailTemplate: "View email template",
      interviewLocation: "Interview location",
      actions: "Actions",
      stages: "Stages",
      preofferTitle: "Pre-offer",
      addMore: "Add pre-offer",
      documentNameEn: "Document name (EN)",
      documentNameAr: "Document name (AR)",
      sendEmail: "Send email",
      inform_candidate: "Inform the candidate by email",
      addToAnotherJob: "Add to job opening",
      sendInformationRequest: "Send information request",
      selectAnswerType: "Select type of answers",
      textAnswers: "Text answers",
      addQuestion: "Add your question",
      enterQuestion: "Enter your question",
      numberOfLetter: "number of letters",
      letters: "Letters",
      addAnotherQuestion: "Add another question",
      videoDuration: "Video duration",
      minutes: "Minutes",
      expirationDate: "Expiration date",
      videoAnswer: "Video",
      approvalStatus: "Approval status",
      school: "Institute/school",
      applicationForm: "Application Form",
    },
    myInterviews: {
      InterviewResult: "Interview Result",
      present: "Present",
      absent: "Absent",
      reschedule: "Reschedule",
      prevInterviews: "Previous Interviews",
      currentInterviews: "Available Interviews",
      myInterviews: "My interviews",
      name: "Name",
      jobOpening: "Job opening",
      interviewDate: "Interview date",
      interviewTime: "Interview time",
      startInterview: "Start interview",
      finishInterview: "Finish interview",
      interviewName: "Interview name",
      interviewType: "Interview type",
      questions: "Questions",
      question: "Question",
      noQuestions: "No questions.",
      skills: "Skills",
      knowledge: "Knowledge",
      attitude: "Attitude",
      noCVS: "No attached CV",
      jobTitle: "Job title",
      nextStage: "Next Stage",
      salary: "Basic Salary",
      Housing: "Housing",
      Transportation: "Transportation",
      noOfOpen: "No. of openings",
      companybranch: "Company branch",
      city: "City",
      closeDate: "Closing date",
      rate: "Rate",
      Residency: "Residence Country",
      ResidencyCity: "Residence City",
      replacement: "Replacement",
      budget: "Budget",

      "Current/Last Salary": "Current/Last Salary",
      "Current/Last position": "Current/Last position",
      "Current/Last company name": "Current/Last company name",
    },
    snackbar: {
      done: "Done Successfully",
      contactModalSuccess:
        "Thank you for contacting us, your message has been sent successfully",
      savedSuccessfuly: "Your data has been saved successfully",
      delayClosingDate: "Closing date has been updated successfully",
      moveToInterviewGrid:
        "The candidate has been transferred to interview grid successfully",
      successMoveShortlisted:
        "The candidate has been transferred to shortlisted grid successfully",
      rejectedSuccessfully: "The request has been disapproved successfully",
      savedSuccessfulyDrJob: "Your job had been successfully posted on Dr. Job",
    },
    wizard: {
      phoneNumber: "Phone",
      uploadImageAllowed: "JPG, PNG, JPEG are only allowed",
      superAdminPhoneNumber: "super admin phone number",
      hello: "Hello",
      addManpowerRequest: "Add Job",
      addAgent: "Add Agent",
      wizardStartMsg: "let us help you build your company setup!",
      companyDetails: "Company details",
      Headquarter: "Headquarter",
      companyFieldsMsg: "What is your company's industry?",
      salaryGradesMsg:
        "What are the salary ranges and currency in your company?",
      notasksMsg: "You have no tasks today!",
      companyFieldsLabel: "Company's industry",
      completeProfile: "Complete profile",
      noTasksMsg: "No tasks today !",
      addAgents: "Add agents",
      addSalaryGrades: "Add salary grades",
      agentsTooltipDownload: "Download the Excel sheet sample first",
      agentsTooltipRemoveColumn:
        "Don't remove or add columns to the excel sheet",
      agentsTooltipFirstLastNameMaxChar:
        "First name, Last name are maxed 50 characters each",
      agentsTooltipRepeatEmails: "Emails cannot be repeated",
      agentsTooltipMandatory: "All columns are mandatory",
      agentsTooltipSalaryNameMaxChar: "salary name is maxed 50 characters each",
      agentsTooltipMaxMinDiff: "Max should be greater than min",
      agentsTooltipSalaryNameUnique: "salary grade name should be unique",
      helpNoteAgents:
        "*Please note that you have to download the agents' sample to be able to upload agents",
      helpNoteSalary:
        "*Please note that you have to download the salary grades' sample to be able to upload salary grades",
    },
    dashboardContent: {
      jobPost: "Job Posts",
      createAccount: "Create Account",
      compeleteProfile: "Complete Profile",
      settings: "Settings",
      dashboardPageContent:
        "Click on the button below to access all features setup",
      opened: "Opened",
      closed: "Closed",
      total: "Total",
      candidates: "Candidates",
      jobOpenings: "Job openings",
      totalJobPosts: "Total of job openings",
      openingJobPosts: "Ongoing job post",
      closedJobPosts: "Closed job post",
      totalApplicants: "Total of candidates",
      talentPool: "Talent Pool",
      websiteApplicants: " Applied through the website",
      addedManualy: "Added Manually",
      suggestedCandidate: "Hrcom suggested candidates",
    },
    Requests: {
      AssignAgent: "Assign Agent",
      ReAssignAgent: "ReAssign Agent",
      updatedDate: "Confirmed Joining date: ",
      confirmDate: "Confirmed joining date",
      Requests: "Approval request",
      requestName: "Request Name",
      creaetdBy: "Created by",
      creaetdOn: "Created On",
      reassign: "Reassign",
      assign: "Assign",
      assignTo: "Assigned to",
      reajectionHaeading: "Rejection reason",
      requestDetailsHeading: "Request Details",
      oldRequests: "Old requests",
      newRequests: "New requests",
      showDetails: "Show details",
      requestDate: "Request date",
      status: "Status",
      requestDetails: "Request details",
      originalExpirationDate: "The original expiration date",
      originalJoiningDate: "Confirm Joining Date",
      joiningDate: "Joining Date",
      viewOffer: "View Offer",
      andCandidateRequestedto: "& candidate requests to change it to",
      requestedExpirationDate: "New expiration date",
      reason: "Reason",
      approval: "Approval",

      Comments: "Comments",
      comment: "Comment",
    },
    applicantProfile: {
      profile: "Profile",
      screening: "Screening questions",
      application: "Applications",
      aaplicationSmallcase: "application",
      answers: "Answers",
      candidateAnswer: "Candidate's answer",
      manuallyAddedApplicant: "This candidate is added manually",
      resume: "Resume",
      downloadResume: "Download resume",
      summary: "Summary",
      advertQuestions: "Advert questions",
      log: "Log",
      candidateLog: "Candidate's log",
      contracts: "Contracts",
      Contract: "Contract",
      summaryTab: {
        advertQuestionsTitle: "Advert questions",
        currentLastSalary: "Current/Last  Salary",
        currency: "Currency",
        currentLastPosition: "Current/Last position",
        currentLastCompany: "Current/Last company name",
        PreOfferAttachements: "Pre-Offer attachments",
        moreInfoLog: "",
        unemployee: "Unemployed",
        employed: "Employed",
        employeeStatus: "Job status",
      },
      applicationsTab: {
        job_title: "Job title",
        applied_on: "Applied on",
        stage_name: "Stage name",
        screening: "Screening questions",
        interviews: "Interviews",
        branch: "Branch",
        request_id: "Request ID",
      },
      applicatProfileTab: {
        education: "Education",
        workExperience: "Work Experience",
        college: "College",
        fieldsOfStudy: "Field of study",
        motherTongue: "Mother tongue",
        InstituteSchool: "Institute/School",
        degree: "Degree",
        gpa: "Grade/GPA",
        category: "Category",
        jobTitle: "Job title",
        companyName: "Company name",
        location: "Location",
        from: "From",
        to: "To",
        file: "File",
        description: "Description",
        addEducation: "Add education",
        editEducation: "Edit education",
        toPresent: "To present",
        addWorkExperience: "Add work experience",
        editWorkExperience: "Edit work experience",
        present: "Present",
        outOf: "Out of",
        customJobTitle: "Enter job title",
        personalInfoTitle: "Personal information",
        firstName: "First name",
        lastName: "Last name",
        dateOfBirth: "Date of birth",
        residency: "Residence Country",
        Residency: "Residency",
        ResidencyCity: "Residence City",
        gender: "Gender",
        maritalStatus: "Marital status",
        contactDetails: "Contact details",
        phoneNumber: "Phone number",
        personalEmail: "Personal Email",
        address: "Address",
        male: "Male",
        female: "Female",
        skillsInfoTitle: "Skills",
        profLevel: " Proficiency Level",
        engProLevel: "What is your proficiency level in English?",
        languages: "Languages",
        profLevelInEnglish: " Proficiency level in English",
        certificateName: "Certificate Name",
        issuingOrganization: "Issuing organization",
        issuedDate: "Issued date",
        expirationDate: "Expiration date",
        noExpirationDate: "This credential does not expire",
        licenses: "Licenses & certifications",
      },
      interviewsTab: {
        interviews: "Interviews",
        interview: "Interview",
        interviewName: "Interview name",
        interviewerName: "Interviewer name",
        interviewDate: "Interview date",
        interviewTime: "Interview time",
        interviewType: "Interview type",
        interviewStatus: "Interview status",
        interviewRate: "Interview Rates",
        interviewComments: "Interview comments",
        pending: "Pending",
        completed: "Completed",
        cancelReason: "Cancel Reason",
      },
      moreInformationRequestTab: {
        moreInfoRequest: "More information request",
        playVideo: "Play video",
        showAnswer: "Show answer",
        minutes: "minute(s)",
        letters: "letter(s)",
        noVideo: " No video yet",
        noText: "No answer yet",
      },
      offerTab: {
        offers: "Offers",
        requestname: "Request name",
        createOn: "Create on",
        status: "Status",
        file: "File",
      },
    },
    addApplicant: {
      addApplicantTitle: "Add applicant",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      phone: "Phone",
      uploadCV: "Upload CV",
      importFromExcel: "Import from Excel",
      allowedStructure:
        "The allowed structure for the Excel sheet is First name, Last name, Email",
      allowedSize: "The allowed file size is 3MB",
      allowedRecordsNumber: "The allowed number of applicants is 100 records",
      allowedType: "Excel sheets are allowed only",
      cvDataBank: "CV databank",
      certainJob: "Certain job",
      addTo: "Add to :",
      informApplicants: "Inform applicant(s)",
      sendSms: "Send SMS",
    },
    pipelineSetup: {
      ActionColorInReports: "Action color in Reports",
      flow_name: "Flow name",
      number_of_stages: "Number of stages",
      created_on: "Created on",
      default_flow: "Set default flow",
      defaultFlowTitle: "flow",
      deleteFlow: "Delete flow",
      deleteFlowModalContent: "Are you sure you want to delete this flow?",
      deleteDefaultFlow:
        "This is the default flow please select another default flow",
      actionsList: "Actions list",
      addPipelineFlow: "Add Pipeline Flow",
      editPipelineFlow: "Edit Pipeline Flow",
      flowNameAr: "Flow name (AR)",
      flowNameEn: "Flow name (EN)",
      action: "Action",
      saveFlow: "Save flow",
      saveStage: "Save stage",
      addStage: "Add stage",
      stageNameAr: "Stage name (AR)",
      stageNameEn: "Stage name (EN)",
      deleteStageModalHeader: "Delete stage",
      deleteStageModalContent: "Are you sure you want to delete this stage ?",
      stageNameUnique: "Stage name must be unique",
      stageHasApplicants:
        "There are candidates on this stage please move them to another stage",
      stage: "Stage",
      exceedFlowsLimitMsg:
        "Sorry you have exceeded your limit. Please upgrade to be able to add more workflows",
    },
    payment: {
      savedCardsTitle: "Saved cards",
      defaultCard: "Default card",
      addNewCard: "Add new card",
      selectCardType: "Select Card Type",
      enterCardDetails: "Enter card details",
      active: "Active",
      statusAddCardSuccessful: "Process is Successfully Completed!",
      statusAddCardFailure: "Sorry, Process failed. Kindly retry again!",
      backToCardsBtn: "Back to my cards",
      deleteCardTitle: "Delete card",
      deleteCardContent: "Are you sure you want to delete this card ?",
      deleteDefaultError:
        "You can't delete the active card, Please active another card first",
      activeCardTitle: "Activte card",
      activeCardContent:
        "Are you sure you want to active this card? Which means that the subscription fees will be collected from it in the future",
      payForSavingCardMsg:
        "1 SAR will be withdrawn from your account to add the card",
    },
    billingInfo: {
      billingInfoTitle: "Billing Information",
      email: "Email",
      firstName: "First name",
      lastName: "Last name",
      address: "Address",
      country: "Country",
      city: "City",
      state: "State",
      street: "Street",
      postcode: "Postcode Code",
      saveBillingInfo: "Save the information for next time",
    },
    customSubscriptionPlan: {
      title: "Custom Your Plan",
      content:
        "More Users, more jobs, more CVs, more live interview minutes, and more.",
      customPlanAction: "Contact us",
    },
    subscribtion: {
      viewReciepe: "View Invoices",
      viewPlan: "Choose your plan",
      popularPlan: "Most Popular",
      freeTrialEndsIn: "Free trial will end in",
      freeTrialEndedIn: "Free trial ended in",
      days: "Days",
      hours: "Hours",
      minutes: "Minutes",
      seconds: "Seconds",
      subscribe: "Subscribe now",
      ends: "Free trial ends in",
      currentPlan: "current plan",
      trialPlan: "Trial Plan",
      basicPlan: "Startup plan",
      advancedPlan: "Growth plan",
      premiumPlan: "Enterprise plan",
      usersNo: "Number of users",
      jobOpeningsNo: "Number of Jobs open Monthly",
      cvsNo: "Number of CVs to apply Monthly",
      webcamMinutesNo: "Number of Minutes for the live interview Monthly",
      createJobsOnline: "Create jobs online (other recruiting agencies)",
      socialMedia: "Posting jobs through social media",
      BrandYourPost: "Brand your post",
      DedicatedAccountManager: "Dedicated account manager",
      careerQuestioner: "Career questionnaire",
      scoreApplicants: "Candidate Screening questions",
      workflow: "Workforce approval Request",
      CustomizePipelineStage: "Customize Pipeline stage",
      ManageRolesAndPermissions: "Manage roles and permissions",
      ESignatures: "Provide offer template and E-signatures",
      jobOfferOnline: "Offer approval request",
      companyBrief: "Company Brief",
      linkWebsite: "Linking your website",
      customColor: "Branded Career Page",
      customLogo: "Branded career page with your logo",
      PreScreeningVideoAssessment: "Pre-screening video assessment",
      PreScreeningTextAssessment: "Pre-screening text assessment",
      LiveVideoInterview: "Live Video interview",
      includeVat: "Including VAT",
      engagementWithCandidates:
        "Engagement system with your candidate (Resal, send emails)",
      subscribeBtn: "Subscribe",
      expirationDate: "Expiration date",
      expirationDateTrial: "Free",
      freeTrialDays: "day(s) free trial",
      monthly: "Monthly",
      annual: "Annual",
      save: "Save",
      SAR: "SAR",
      unlimited: "Unlimited",
      payNow: "Pay now",
      paymentSteps: "Payment steps",
      downgradeWarning:
        "Downgrading your subscription plan, may cause losing control on some added data",
      cancelSubscription: "Cancel subscription",
      cancelSubscriptionModalHeader:
        "Are you sure you want to cancel your subscription?",
      cancelSubscriptionModalContent:
        "By Cancellation your account will be deactivated",
      youHave: "You have",
      refundStatement:
        "SAR, will be refunded to you according to the HRcom policy",
      myWallet: "My wallet",
      walletUseNote: "will be used in next transactions",
      renew: "Renew",
      unsubscribe: "Unsubscribe",
      perMonth: "per month",
      noRegisteredCards: "Sorry, no used before cards found to show",
      billedAnnually: "Billed annually",
      billedMonthly: "Billed monthly",
      lostDataPipeline: "you will have to delete more info request action from",
      first: "first",
      request: "Request",
      googleSearch: "Jobs on Google search",
      overview: "Overview",
      companyBranding: "Company Branding",
      matrix: "Authority Matrix",
      tools: "Assessment Tools",
      jobPost: "Job Posting",
      customerSuccess: "Customer Success",
      advancedFeatures: "Advanced Features",
      sendSMSToCandidate: "Send SMS Messages to candidates",
      singleSignOn: "Single sign-on (SSO) ",
    },
    promoCode: {
      includesVat: "VAT 15%",
      discount: "Discount",
      taxNumber: "The tax number : 310498582100003",
      name: "Promo code",
      total: "Total",
    },
    invoice: {
      tax: "Tax Invoice",
      invoiceNumber: "Invoice Number",
      monthly: "Monthly",
      companyName:
        "Eighth Generation Communications and Information Technology Company",
      address: "Riyadh, Al Rabwah District",
      date: "Date",
      time: "Time",
      vatNumber: "Vat Number",
      items: "Items",
      quantity: "Quantity",
      price: "Price",
      subtotal: "Subtotal",
      discount: "Discount",
      includesVat: "price includes VAT (15%)",
      total: "Total",
      annual: "annual",
      from: "From",
      address1: "Address 1",
      address2: "Address 2",
      invoiceDate: "Invoice Date",
      dueDate: "Due Date",
      viewInvoice: "View Invoice",
      to: "To",
      serialNumber: "Serial Number",
      code: "code",
      Item: "Item",
      priceWithoutVat: "Price doesn`t include VAT",
      vat: "VAT",
      vatSAR: "VAT SAR",
      totalSAR: "Total SAR",
      SAR: "SAR",
      priceIncludesVat: "price includes VAT",
    },
    confirmOffer: {
      name: "Value",
      Title: "Confirm offer value",
      Message: "Please confirm the offer total value by entering it below",
      totalSalary: "Total Salary",
      salary: "Basic Salary",
      ShowPercentage: "Shows as a percentage in the job offer",
    },
    offerTemplateSetup: {
      descriptionAr: "Description (AR)",
      descriptionEn: "Description (EN)",
      coverLetter: "Cover letter",
      sendOffer: "Send offer",
      ApprovedBy: "Approved By :",
      Signed: "Signed",
      JoiningDate: "Expected joining date :",
      or: "or",
      offerJoiningDate: "Expected joining date",
      offerAcceptedDate: "Offer accepted date",
      jobTitle: "Job Title",
      reoffer: "Reoffer",
      offer: "Offer",
      editNotification: "This template is editable",
    },
    footer: {
      address: "Alnoor Way, Riyadh 12222",
    },
    integrations: {
      unifonic: {
        status: "Status",
        keyId: "Key ID",
        senderID: "Sender ID",
        description:
          "Unifonic is a customer engagement platform that enables you to delight candidates with remarkable experience",
        active: "Active",
        inactive: "Inactive",
        activate: "Activate",
        edit: "Edit",
        learnMore: "Learn More",
      },
      office365: {
        status: "Status",
        showCalendar: "Show Calendar",
        keyId: "Key ID",
        tenantID: "Office 365 Tenant ID",
        description:
          "Integrate with your organization's Office365 account to view calendars including free/busy time for each user when scheduling interviews",
        active: "Active",
        inactive: "Inactive",
        activate: "Activate",
        edit: "Edit",
        learnMore: "Learn more",
        grantAccess: "Grant access",
        requiredGrantAccess:
          "Grant access step is required to activate your calendar",
        notGrantAccessed: "Guaranty access first to enable the save",
        grantAccessNote:
          "Click the 'Grant access' link, to grant HRcom access to your company's Office 365 calendar events. If you are not the admin of your Office 365 account, copy the link and send to your Office 365 admin to grant access for you.",
      },
      emailSync: {
        syncAccount: "Sync your account",
        connectEmail: "Connect your Email account to HRcom",
        syncEmail: "Sync Email",
        delete: "Delete",
        deleteAccout: "Delete Account",
        deleteAccountConfirmation:
          "Are you sure you want to delete this account?",
        reminderMessage:
          "Connecting your email account to HRcom is easy, Start by selecting your email provider",
        remindLater: "Remind me later",
        donotAllow: "Don't allow",
      },
    },
    socialLogin: {
      sectionTitle: "Or continue with",
      googleBtn: "Google",
      linkedinBtn: "Linkedin",
      microsoftBtn: "Microsoft",
      prerequestionTitle: "Basic Info",
    },
    vatNumber: {
      header: "Vat Number",
      is_saudia_arabian_company: "Saudi Arabian Company",
      vat_number: "Vat Number",
      notes:
        "Due to Zakat, Tax and Customs Authority policies, it's mandatory to enter your VAT number",
    },
    favorite: "Favorite",
    organizationStructure: {
      addDepartment: "Add Department",
      editDepartment: "Edit Department",
      departmentNameEn: "Department Name (EN)",
      departmentNameAr: "Department Name (AR)",
      headDepartment: "Head Department",
      hiringManager: "Hiring Manager",
      headDepartment: "Head Department",
      hiringManager: "Hiring Manager",
      emptyCaption: "Now, You can create your organizational structure",
      deleteDepartment:
        "Are you sure you want to delete this Department and the related ones?",
      deleteDepartmentHeading: "Delete Department",
    },
    positionChart: {
      levels: "levels",
      pleaseSelectLevel: "Please Select Level for every item",
      noData: "No data",
      pleaseSelect: "Please Select",
      JobDescriptionAR: "Job description (AR)",
      JobDescriptionEN: "Job description (EN)",
      addPosition: "Add Position",
      editPosition: "Edit Position",
      positionNameEn: "Position Name (EN)",
      positionNameAr: "Position Name (AR)",
      salaryGrade: "Salary Grade",
      category: "Category",
      emptyCaption: "Now, You can create your Positions Chart",
      deletePositionHeading: "Delete Position",
      deletePosition:
        "Are you sure you want to delete this Position and the related ones?",
    },
    templates: {
      arabic_template: "Arabic Template",
      english_template: "English Template",
    },
  },
  renainingHours: "hrs remain on the trial version",
};
