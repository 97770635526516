import { axiosInstance } from "./config";

let handlerEnabled = true;

const addTicketsRequest = async (data) => {
  return await axiosInstance.post(`/admin/TicketSettings`, data, {
    handlerEnabled,
  });
};
const editTicketsRequest = async (data) => {
  return await axiosInstance.put(
    `/admin/nationality_vacation_balance/${data.id}`,
    data,
    { handlerEnabled }
  );
};
const deleteTicketsRequest = async (data) => {
  return await axiosInstance.delete(
    `/admin/nationality_vacation_balance/${data}`,
    { handlerEnabled }
  );
};
const getAllTicketsRequest = async ({ page_size, pageNumber }) => {
  return await axiosInstance.get(
    `/admin/TicketSettings?page_size=${page_size}&page_number=${pageNumber}`,
    { handlerEnabled }
  );
};

const getSingleTicketRequest = async ({ id }) => {
  return await axiosInstance.get(`/admin/nationality_vacation_balance/${id}`, {
    handlerEnabled,
  });
};
export default {
  getSingleTicketRequest,
  getAllTicketsRequest,
  deleteTicketsRequest,
  editTicketsRequest,
  addTicketsRequest,
};
