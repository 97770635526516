import * as types from "../types/tickets";
export const editTicketsRequest = (payload) => {
  return {
    type: types.EDIT_TICKET_REQUEST,
    payload,
  };
};
export const editTicketsRecieve = (payload) => {
  return {
    type: types.EDIT_TICKET_RECIEVE,
    payload,
  };
};

export const getAllTicketsRequest = (payload) => {
  return {
    type: types.GET_ALL_TICKETS_REQUEST,
    payload,
  };
};
export const getAllTicketsRecieve = (payload) => {
  return {
    type: types.GET_ALL_TICKETS_RECIEVE,
    payload,
  };
};

export const getSingleTicketRequest = (payload) => {
  return {
    type: types.GET_SINGLE_TICKET_REQUEST,
    payload,
  };
};
export const getSingleTicketRecieve = (payload) => {
  return {
    type: types.GET_SINGLE_TICKET_RECIEVE,
    payload,
  };
};
export const addTicketsRequest = (payload) => {
  return {
    type: types.ADD_TICKETS_REQUEST,
    payload,
  };
};
export const addTicketsRecieve = (payload) => {
  return {
    type: types.ADD_TICKETS_RECIEVE,
    payload,
  };
};

export const deleteTicketsRequest = (payload) => {
  return {
    type: types.DELETE_TICKETS_REQUEST,
    payload,
  };
};
export const deleteTicketsRecieve = (payload) => {
  return {
    type: types.DELETE_TICKETS_RECIEVE,
    payload,
  };
};
